import React from 'react'
import './styles/Promo.scss'
import imOne from '../../assets/images/search.svg'
import imTwo from '../../assets/images/ssl.svg'
import imThree from '../../assets/images/servers.svg'
import imFour from '../../assets/images/database-storage.svg'
import imFive from '../../assets/images/mail.svg'
import imSix from '../../assets/images/support.svg'
// import imOne from '../../assets/images/progress__monochromatic.svg'
// import imOne from '../../assets/images/analytics_process_monochromatic.svg'
// import imOneAnim from '../../assets/images/one-block--anim.svg'

export default class Promo extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="promo" id="promo">
                    <div className="promo__container">
                        <h1 className="promo__container--title">
                            Что идет<br/> дополнительно,
                            <div className="promo__container--title--accent">
                                бесплатно.
                            </div>
                        </h1>
                        <div className="promo__container--block">
                            <div>
                                <div className="promo__container--block--box">
                                    <img src={imOne}/>
                                    <p>Домен</p>
                                </div>
                                <div className="promo__container--block--box">
                                    <img src={imTwo}/>
                                    <p>SSL-сертификат (HTTPS)</p>
                                </div>
                                <div className="promo__container--block--box">
                                    <img src={imThree}/>
                                    <p>Хостинг</p>
                                </div>
                            </div>
                            <div>
                                <div className="promo__container--block--box">
                                    <img src={imFour}/>
                                    <p>Место под сайт</p>
                                </div>
                                <div className="promo__container--block--box">
                                    <img src={imFive}/>
                                    <p>Корпоративная почта</p>
                                </div>
                                <div className="promo__container--block--box">
                                    <img src={imSix}/>
                                    <p>Техническая поддержка</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
