import React from 'react'
import { Link } from 'react-router-dom'
import './styles/Footer.scss'

import imgBack from '../../assets/images/back-white-wall.svg'
import dl from '../../assets/images/dl-white.svg'
import vk from '../../assets/images/vk-social-network-logo.svg'
import instagram from '../../assets/images/instagram.svg'

export default class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getDateThisYearh() {
        const date = new Date();
        return date.getFullYear()
    }

    render() {
        return (
            <React.Fragment>
                <div className="footer" id="footer">
                    {/*<img className="footer__block--img--back" src={imgBack}/>*/}
                    <div className="footer__block">
                        <div className="footer__block--logo">
                            <img className="footer__block--logo--img" src={dl}/>
                            <div className="footer__block--logo--text">
                                Одна из лучших студий по созданию сайтов.
                            </div>
                            <div className="footer__block__menu--soc">
                                <a href="https://vk.com/landlix" target="_blank">
                                    <img src={vk}/>
                                </a>
                                <a href="https://www.instagram.com/landlix.ru" target="_blank">
                                    <img src={instagram}/>
                                </a>
                            </div>
                            <div className="footer__block--logo--text">
                                &copy; Copyright {this.getDateThisYearh()} <b>Landlix.ru</b>
                            </div>
                        </div>
                        <div className="footer__block__menu--items">
                            <div className="footer__block__menu">
                                <div className="footer__block__menu--title">
                                    Основное
                                </div>
                                <Link className="footer__block__menu--item" to="/">Главная</Link>
                                <Link className="footer__block__menu--item" to="/prices">Цены</Link>
                                {/*<Link className="footer__block__menu--item" to="/about-us">О нас</Link>*/}
                            </div>
                            <div className="footer__block__menu">
                                <div className="footer__block__menu--title">
                                    Информация
                                </div>
                                <Link className="footer__block__menu--item" to="/disclamer">Пользовательское соглашение</Link>
                                <Link className="footer__block__menu--item" to="/privacy-policy">Политика конфиденциальности</Link>
                            </div>
                            <div className="footer__block__menu">
                                <div className="footer__block__menu--title">
                                Связаться
                                </div>
                                <Link className="footer__block__menu--item" to="/contacts">Контакты</Link>
                                <a className="footer__block__menu--item" href="mailto:feedback@landlix.ru">sales@landlix.ru</a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
