import React from 'react'
import './styles/ListSite.scss'
import imOneAnim from '../../assets/images/one-block--anim.svg'
import imOne from '../../assets/images/technext.png'
import imTwo from '../../assets/images/zarub.fun.png'
import imThree from '../../assets/images/drujelub.png'
import imFour from '../../assets/images/follo.png'
import imFive from '../../assets/images/juduy2.png'
import imSix from '../../assets/images/fitnes-wensyydt26.png'
import imSeven from '../../assets/images/barberclubsdkj23.png'
import imEight from '../../assets/images/yellosadhueHy7.png'
import imNine from '../../assets/images/topsmotjhdGd72.png'
import imTen from '../../assets/images/elenalimsadhJHd72.png'

export default class ListSite extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            slides: 0,
            // arrBox: [
            //     <figure ref={(node) => (this.box = node)} className="list-site--container--block--box">
            //     </figure>,
            //     <figure className="list-site--container--block--box">
            //     </figure>,
            //     <figure className="list-site--container--block--box">
            //     </figure>,
            //     <figure className="list-site--container--block--box">
            //     </figure>,
            //     <figure className="list-site--container--block--box">
            //     </figure>
            // ],
            comp: false,
            currTransl: [],
            num: 0,
            list: [
                {
                    img: imOne
                },
                {
                    img: imTen
                },
                {
                    img: imThree
                },
                {
                    img: imNine
                },
                {
                    img: imFive
                },
                {
                    img: imSix
                },
                {
                    img: imSeven
                },
                {
                    img: imEight
                },
                {
                    img: imFour
                },
                {
                    img: imTwo
                },
            ],
            listNew: [
                {
                    img: imTwo
                },
                {
                    img: imTen
                },
                {
                    img: imEight
                },
                {
                    img: imNine
                },
                {
                    img: imSeven
                },
                {
                    img: imSix
                },
                {
                    img: imFive
                },
                {
                    img: imThree
                },
                {
                    img: imFour
                },
                {
                    img: imOne
                },
            ]
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="list-site" id="list-site">
                    <div className="list-site--container">
                        <div className="list-site--container--slide">
                            <div className="list-site--container--slide--move">
                                {
                                    this.state.list.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="list-site--container--slide--move--box"
                                            >
                                                <img src={item.img} alt=""/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="list-site--container--slide">
                            <div className="list-site--container--slide--move--two">
                                {
                                    this.state.list.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="list-site--container--slide--move--two--box"
                                            >
                                                <img src={item.img} alt=""/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="list-site--container--slide">
                            <div className="list-site--container--slide--move">
                                {
                                    this.state.listNew.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="list-site--container--slide--move--box"
                                            >
                                                <img src={item.img} alt=""/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
