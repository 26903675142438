import React from 'react'
import './styles/WeEmail.scss'
import imOne from '../../assets/images/search.svg'
import imTwo from '../../assets/images/ssl.svg'
import imThree from '../../assets/images/servers.svg'
import imFour from '../../assets/images/database-storage.svg'
import imFive from '../../assets/images/mail.svg'
import imSix from '../../assets/images/support.svg'
import SendEmail from "../SendEmail";
import {Link} from "react-router-dom";
// import imOne from '../../assets/images/progress__monochromatic.svg'
// import imOne from '../../assets/images/analytics_process_monochromatic.svg'
// import imOneAnim from '../../assets/images/one-block--anim.svg'

export default class WeEmail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSend = node)}
                />
                <div className="promos" id="promo">
                    <div className="promos__container">
                        <h1 className="promos__container--title">
                            <div className="promos__container--title--one">
                                НАЧНЕМ?
                            </div>
                            <div className="promos__container--title--info">
                                Мы разработаем для Вас индивидуальное решение с учетом Ваших нужд и особенностей Вашей компании, а так же мы осуществляем поддержку на всех стадиях работы с Вами: консультация, выбор, внедрение, доработка.
                            </div>
                        </h1>
                        <div className="promos__container--block">
                            <div className="head-new__container--box--four" onClick={() => {
                                this.modalSend.open()
                            }}>
                                Заказать
                            </div>
                            <Link
                                className="head-new__container--box--five"
                                to="/prices"
                            >
                                Подробнее
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
