import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import logo from '../../assets/images/landlix.svg';

export default class Header extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {

        let { headerOther } = this.props;

        return (
            <React.Fragment>
                <div className={`header ${headerOther ? 'header--other' : ''}`}>
                    <div className="header__block">
                        <div className="header__block__logo">
                            <NavLink
                                to="/"
                                className="header__block__nav--link"
                            >
                                <img src={logo} alt=""/>
                            </NavLink>
                            <NavLink
                                to="/prices"
                                className="header__block__nav--link"
                                activeClassName="header__block__nav--link--active"
                            >
                                Цены
                            </NavLink>
                            {/*<NavLink*/}
                            {/*    to="/about-us"*/}
                            {/*    className="header__block__nav--link"*/}
                            {/*    activeClassName="header__block__nav--link--active"*/}
                            {/*>*/}
                            {/*    О нас*/}
                            {/*</NavLink>*/}
                            <NavLink
                                to="/contacts"
                                className="header__block__nav--link"
                                activeClassName="header__block__nav--link--active"
                            >
                                Контакты
                            </NavLink>
                        </div>
                        <div className="header__block__nav">
                            <a
                                href="mailto:sales@landlix.ru"
                                className="header__block__nav--link white"
                            >
                                sales@landlix.ru
                            </a>
                        </div>
                    </div>
                </div>
                <nav className="header__mobileMenu">
                    <div className="hamburger-menu">
                        <input
                            ref={(node) => {this.inputOpen = node}}
                            id="menu__toggle"
                            type="checkbox"
                            // checked={this.state.active}
                            // onClick={() => {
                            //     if (!this.state.active) {
                            //         this.setState({
                            //             active: !this.state.active
                            //         })
                            //     }
                            // }}
                        />
                        <label
                            className="menu__btn"
                            htmlFor="menu__toggle"
                        >
                            <span/>
                        </label>
                        <div className="menu__box">
                            {/*<div className="menu__box--header">*/}
                                {/*<div>Mashchenko Sergey</div>*/}
                            {/*</div>*/}
                            <div className="menu__container">
                                <NavLink
                                    to="/"
                                    className="header__block__nav--link"
                                    activeClassName="header__block__nav--link--active"
                                    onClick={() => {
                                        this.inputOpen.checked = false
                                    }}
                                >
                                    Главная
                                </NavLink>
                                <NavLink
                                    to="/prices"
                                    className="header__block__nav--link"
                                    activeClassName="header__block__nav--link--active"
                                    onClick={() => {
                                        this.inputOpen.checked = false
                                    }}
                                >
                                    Цены
                                </NavLink>
                                <NavLink
                                    to="/contacts"
                                    className="header__block__nav--link"
                                    activeClassName="header__block__nav--link--active"
                                    onClick={() => {
                                        this.inputOpen.checked = false
                                    }}
                                >
                                    Контакты
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}
