import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import logo from '../../assets/images/menu.svg';
import logoClose from '../../assets/images/menu-close.svg';
import dl from '../../assets/images/dl-white.svg'
import './styles/left-side.scss'

export default class LeftSide extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }

    open() {
        this.setState({
            active: true
        })
    }

    close() {
        this.setState({
            active: false
        })
    }

    render() {

        let { active } = this.state;

        return (
            <React.Fragment>
                <div
                    className={`left-side ${
                            active ? 'left-side--open' : ''
                        }`}
                >
                    <div
                        className="left-side__logo"
                    >
                        <img
                            src={active ? logoClose : logo}
                            alt=""
                            onClick={() => {
                                this.setState({
                                    active: !this.state.active
                                })
                            }}
                        />
                    </div>
                    <div
                        className={`left-side__block ${
                                active ? 'left-side__block--open' : ''
                            }`}
                        style={{
                            flexDirection: 'column'
                        }}
                    >
                        <div className="left-side__block__menu--items">
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Разработка
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to='/prices/dev/card'
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Сайт-визитка
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to='/prices/dev/landing-page'
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Лендинг
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to='/prices/dev/business-site'
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Бизнес-сайт
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to='/prices/dev/online-store'
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Интернет-магазин
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to='/prices/dev/more'
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Доп. Услуги
                                </Link>
                            </div>
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Дизайн
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to="/prices/design/site"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Дизайн сайта
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to="/prices/design/logo"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Заказать логотип
                                </Link>
                            </div>
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Маркетинг
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to="/prices/seo/yd"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Яндекс. Директ
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to="/prices/seo/context"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Контекстная реклама
                                </Link>
                            </div>
                        </div>
                        <div className="left-side__block__menu--items">
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Основное
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to="/"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Главная
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to="/prices"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Цены
                                </Link>
                                {/*<Link*/}
                                {/*    className="left-side__block__menu--item" to="/about-us"*/}
                                {/*    onClick={() => {*/}
                                {/*        this.setState({*/}
                                {/*            active: !this.state.active*/}
                                {/*        })*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    О нас*/}
                                {/*</Link>*/}
                            </div>
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Информация
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to="/disclamer"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Пользовательское соглашение
                                </Link>
                                <Link
                                    className="left-side__block__menu--item" to="/privacy-policy"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Политика конфиденциальности
                                </Link>
                            </div>
                            <div className="left-side__block__menu left-side__block__menu--men">
                                <div className="left-side__block__menu--title">
                                    Связаться
                                </div>
                                <Link
                                    className="left-side__block__menu--item" to="/contacts"
                                    onClick={() => {
                                        this.setState({
                                            active: !this.state.active
                                        })
                                    }}
                                >
                                    Контакты
                                </Link>
                                <a className="left-side__block__menu--item" href="mailto:sales@landlix.ru">sales@landlix.ru</a>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`left-side__info ${
                            active ? 'left-side__info--open' : ''
                        }`}
                    >
                        <div className="left-side__info--item">
                            Идея
                        </div>
                        <div className="left-side__info--item">
                            Дизайн
                        </div>
                        <div className="left-side__info--item">
                            Разработка
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
