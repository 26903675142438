import React from 'react'
import './styles/TwoBlock.scss'
import { Link } from "react-router-dom";
import img from '../../assets/images/about-chat.svg'

export default class TwoBlock extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let {
            data
        } = this.props;
        /*********************
         *	Helpers Code
         ********************/
        /**
         *  @function   DOMReady
         *
         *  @param callback
         *  @param element
         *  @param listener
         *  @returns {*}
         *  @constructor
         */
        const DOMReady = ((
            callback  = () => {},
            element   = document,
            listener  = 'addEventListener'
        ) => {
            return (element[listener]) ? element[listener]('DOMContentLoaded', callback) : window.attachEvent('onload', callback);
        });

        /**
         *  @function   ProjectAPI
         *
         *  @type {{hasClass, addClass, removeClass}}
         */
        const ProjectAPI = (() => {
            let hasClass,
                addClass,
                removeClass;

            hasClass = ((el, className) => {
                if (el === null) {
                    return;
                }

                if (el.classList) {
                    return el.classList.contains(className);
                }
                else {
                    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
                }
            });

            addClass = ((el, className) => {
                if (el === null) {
                    return;
                }

                if (el.classList) {
                    el.classList.add(className);
                }
                else if (!hasClass(el, className)) {
                    el.className += ' ' + className
                }
            });

            removeClass = ((el, className) => {
                if (el === null) {
                    return;
                }

                if (el.classList) {
                    el.classList.remove(className);
                }
                else if (hasClass(el, className)) {
                    let reg = new RegExp('(\\s|^)' + className + '(\\s|$)');

                    el.className = el.className.replace(reg, ' ');
                }
            });

            return {
                hasClass:     hasClass,
                addClass:     addClass,
                removeClass:  removeClass
            };
        })();


        /*********************
         *	Application Code
         ********************/
        /**
         *  @function   readyFunction
         *
         *  @type {Function}
         */
        const readyFunction = (() => {

            const KEY_UP    = 38;
            const KEY_DOWN  = 40;

            let scrollingClass          = 'js-scrolling',
                scrollingActiveClass    = scrollingClass + '--active',
                scrollingInactiveClass  = scrollingClass + '--inactive',

                scrollingTime           = 1350,
                scrollingIsActive       = false,

                currentPage             = 1,
                countOfPages            = document.querySelectorAll('.' + scrollingClass + '__page').length,

                prefixPage              = '.' + scrollingClass + '__page-',

                _switchPages,
                _scrollingUp,
                _scrollingDown,

                _mouseWheelEvent,
                _keyDownEvent,

                init;

            /**
             *  @function _switchPages
             *
             *  @private
             */
            _switchPages = () => {

                let _getPageDomEl;

                /**
                 *  @function _getPageDomEl
                 *
                 *  @param page
                 *  @returns {Element}
                 *  @private
                 */
                _getPageDomEl      = (page = currentPage) => {
                    return document.querySelector(prefixPage + page);
                };

                scrollingIsActive  = true;


                ProjectAPI.removeClass(
                    _getPageDomEl(),
                    scrollingInactiveClass
                );
                ProjectAPI.addClass(
                    _getPageDomEl(),
                    scrollingActiveClass
                );

                ProjectAPI.addClass(
                    _getPageDomEl(currentPage - 1),
                    scrollingInactiveClass
                );

                ProjectAPI.removeClass(
                    _getPageDomEl(currentPage + 1),
                    scrollingActiveClass
                );


                setTimeout(
                    () => {
                        return scrollingIsActive = false;
                    },
                    scrollingTime
                );
            };
            /**
             *  @function _scrollingUp
             *
             *  @private
             */
            _scrollingUp = () => {
                if (currentPage === 1) {
                    return;
                }

                currentPage--;

                _switchPages();
            };
            /**
             *  @function _scrollingDown
             *
             *  @private
             */
            _scrollingDown = () => {
                if (currentPage === countOfPages) {
                    return;
                }

                currentPage++;

                _switchPages();
            };
            /**
             *  @function _mouseWheelEvent
             *
             *  @param e
             *  @private
             */
            _mouseWheelEvent = (e) => {
                if (scrollingIsActive) {
                    return;
                }

                if (e.wheelDelta > 0 || e.detail < 0) {
                    _scrollingUp();
                }
                else if (e.wheelDelta < 0 || e.detail > 0) {
                    _scrollingDown();
                }
            };
            /**
             *  @function _keyDownEvent
             *
             *  @param e
             *  @private
             */
            _keyDownEvent = (e) => {
                if (scrollingIsActive) {
                    return;
                }

                let keyCode = e.keyCode || e.which;

                if (keyCode === KEY_UP) {
                    _scrollingUp();
                }
                else if (keyCode === KEY_DOWN) {
                    _scrollingDown();
                }
            };

            /**
             *  @function init
             *
             *  @note     auto-launch
             */
            init = (() => {
                document.addEventListener(
                    'mousewheel',
                    _mouseWheelEvent,
                    false
                );
                document.addEventListener(
                    'DOMMouseScroll',
                    _mouseWheelEvent,
                    false
                );

                document.addEventListener(
                    'keydown',
                    _keyDownEvent,
                    false
                );
            })();

        });


        /**
         *  Launcher
         */
        DOMReady(readyFunction);

        return (
            <React.Fragment>
                {/*<div className="two-block" id="two-block">*/}
                {/*    /!*<div className="about-us__back--one"/>*!/*/}
                {/*    <div className="two-block__block">*/}
                {/*        <div className="two-block__block--text">*/}
                {/*            <div className="two-block--title">*/}
                {/*                <svg className="editorial"*/}
                {/*                       xmlns="http://www.w3.org/2000/svg"*/}
                {/*                       xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*                    // viewBox="0 24 150 28"*/}
                {/*                       preserveAspectRatio="none">*/}
                {/*                    <defs>*/}
                {/*                        <img src={img} id="imgare2" style={{width: '5rem'}}/>*/}
                {/*                        <path id="cloud-1"*/}
                {/*                              d="M370,123a65.31,65.31,0,0,0-10,.8A61.57,61.57,0,0,0,298.5,63c-1.5,0-3,.1-4.5.2a93.53,93.53,0,0,0-175.2-4.7A91.49,91.49,0,0,0,93.5,55,93.55,93.55,0,0,0,0,148.5C0,200.1,40.4,248,92,248H370c35.3,0,64-25.7,64-61A64.06,64.06,0,0,0,370,123Z"/>*/}
                {/*                        <path id="cloud-2"*/}
                {/*                              d="M513.9,168.1A89,89,0,0,0,426,93h-.1a48.52,48.52,0,0,0-48.4-46,45.69,45.69,0,0,0-12.9,1.8A135.5,135.5,0,0,0,138.1,77.3,100.5,100.5,0,1,0,100.5,271h411a51.48,51.48,0,0,0,2.4-102.9Z"/>*/}
                {/*                    </defs>*/}
                {/*                    <g className="parallax">*/}
                {/*                        /!*<use xlinkHref="#cloud-1" x="50" y="0" fill="#80aaff40"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-2" x="50" y="3" fill="#80aaff40"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-1" x="50" y="6" fill="#ffffff54"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-2" x="50" y="9" fill="#80aaff40"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-1" x="50" y="12" fill="#80aaff40"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-2" x="50" y="15" fill="#ffffff54"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-1" x="50" y="18" fill="#80aaff40"/>*!/*/}
                {/*                        /!*<use xlinkHref="#cloud-2" x="50" y="21" fill="#fff"/>*!/*/}
                {/*                        <use xlinkHref="#imgare2" x="50" y="22" fill="#fff"/>*/}
                {/*                    </g>*/}
                {/*                </svg>*/}
                {/*            </div>*/}
                {/*            /!*{*!/*/}
                {/*                /!*data.desc*!/*/}
                {/*            /!*}*!/*/}
                {/*            asdasdasd*/}
                {/*            /!*{*!/*/}
                {/*                /!*data.link &&*!/*/}
                {/*                /!*<Link*!/*/}
                {/*                    /!*class="two-block__block--text--link"*!/*/}
                {/*                    /!*to={data.link}*!/*/}
                {/*                /!*>*!/*/}
                {/*                    /!*Подробнее*!/*/}
                {/*                /!*</Link>*!/*/}
                {/*            /!*}*!/*/}
                {/*        </div>*/}
                {/*        /!*<div className="two-block__block--img">*!/*/}
                {/*            /!*<img src={data.img}/>/*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                <section className="slider-pages">
                    <article className="js-scrolling__page js-scrolling__page-1 js-scrolling--active">
                        <div className="slider-page slider-page--left">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                </div>
                            </div>
                        </div>
                        <div className="slider-page slider-page--right">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                    <h1 className="slider-page__title slider-page__title--big">
                                        Gray & White - Skewed Slider with Scrolling
                                    </h1>
                                    <h2 className="slider-page__title">
                                        Start of your story
                                    </h2>
                                    <p className="slider-page__description">
                                        Please scroll down or press the down arrow on your keyboard
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="js-scrolling__page js-scrolling__page-2">
                        <div className="slider-page slider-page--left">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                    <h1 className="slider-page__title">
                                        Now you are here
                                    </h1>
                                    <p className="slider-page__description">
                                        Please continue scrolling
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="slider-page slider-page--right">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="js-scrolling__page js-scrolling__page-3">
                        <div className="slider-page slider-page--left">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                </div>
                            </div>
                        </div>
                        <div className="slider-page slider-page--right">
                            <div className="slider-page--skew">
                                <div className="slider-page__content">
                                    <h1 className="slider-page__title">
                                        Final is just the beginning
                                    </h1>
                                    <p className="slider-page__description">
                                        Feel free to follow me on <a className="slider-page__link"
                                                                     href="https://twitter.com/WispProxy"
                                                                     target="_blank">Twitter</a>
                                        and check of <a className="slider-page__link"
                                                        href="https://codepen.io/WispProxy/pens/public"
                                                        target="_blank">my other works</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </React.Fragment>
        )
    }
}
