import React from 'react'

import './styles/Prices.scss'

import backOne from '../../assets/images/top-back-vall.svg'
import imSix from "../../assets/images/back-line-two.svg";
import imSeven from "../../assets/images/back-line-three.svg";
import imgOne from "../../assets/images/block-img-one.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";
import ContactUs from "../../components/Conact-us";
import SendEmail from "../SendEmail";
import {Link, NavLink, Redirect, Route, Switch} from "react-router-dom";
import MoreAll from "./components/MoreAll";
import PricesAll from "./index";

export default class More extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            prices: [
                {
                    id: 'one',
                    name: 'Полный цикл услуг',
                    cost: 'От разработки фирменного стиля до продвижения сайта.',
                    img: imgOne
                },
                {
                    id: 'two',
                    name: 'Гибкость',
                    cost: 'Работаем с клиентом в одной команде.',
                    img: imgTwo
                },
                {
                    id: 'three',
                    name: 'Мобильная версия',
                    cost: 'При разработке получаете мобильную версию сайта.',
                    img: imgThree
                },
                {
                    id: 'four',
                    name: 'Хостинг',
                    cost: 'Бесплатный хостинг на высокоскоростных и надежных серверах.',
                    img: imgFour
                },
                {
                    id: 'five',
                    name: 'Техническая поддержка',
                    cost: 'Бесплатная поддержка до 3 месяцев.',
                    img: imgFive
                },
                {
                    id: 'six',
                    name: 'Функциональная  CMS',
                    cost: 'Понятная и простая панель управления.',
                    img: imgSix
                },
            ]
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        let { sections } = this.props;
        document.title = this.props.title && this.props.title

        let moreInfo = [
            {
                name: 'WordPress',
                text: 'Работа с сайтом на WordPress',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 150 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если у Вас уже есть сайт в котором Вы используете CMS "WordPress" и вам необходимо его доработать или изменить, в том числе плагины в нем. Вам будет удобен этот вариант, так как Вам нужно изменить "Точечно" что-то на сайте.',
                href: '/prices/online-store',
                typeSend: 'storePage'
            },
            {
                name: 'WordPress',
                text: 'Работа с сайтом на WordPress',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 150 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если у Вас уже есть сайт на котором Вы используете CMS "WordPress" и вам необходимо его доработать или изменить, в том числе плагины в нем. Вам будет удобен этот вариант, так как Вам нужно изменить "Точечно" что-то на сайте.',
                dev: [
                    {
                        one: [
                            'Продающий дизайн.',
                            'Адаптивная верстка.',
                            'Сайт из 10 типовых страниц, в которые входит: кабинет клиента с историей заказов, каталог, карточка товара и корзина.',
                            'Система скидок и цен.',
                            'Фильтры и сортировка товаров.',
                            'Система доставки.',
                            'Наполнение материалами (материалы предоставляются заказчиком и в электронном виде).',
                            'Подключение резервного копирования данных.',
                            'Установка онлайн-чата.',
                        ],
                        two: [
                            'Домен в подарок, стоимостью не более чем 5% от цены сайта.',
                            'SSL-сертификат (HTTPS), на 3 месяца.',
                            'Хостинг на 3  месяц бесплатно.',
                            '4 гб. места под сайт.',
                            `Корпоративная почта имя@имя-сайта.ru`,
                            `Система управления сайтом (CMS).`,
                            `Техническая поддержка 3 месяц.`,
                        ],
                        three: [
                            'SEO панель для продвижения сайта.',
                            'Установка счетчика посещаемости и карты кликов.',
                            'Регистрация в поисковых системах Google.com, Yandex.ru.',
                            'Добавление организации в карты поисковиков: Google, Яндекс. (По желанию)',
                        ],
                    },
                ],
                href: '/prices/online-store',
                typeSend: 'storePage'
            },
        ]

        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSending = node)}
                />
                <div className="prices__block prices__block--center">
                    <div
                        className="prices__menu"
                        style={{
                            margin: 0
                        }}
                    >
                        <NavLink
                            to='/prices/more/wp'
                            className="prices__menu--link"
                            activeClassName="prices__menu--link--active"
                            // onClick={() => this.check()}
                        >
                            WordPress
                        </NavLink>
                        <NavLink
                            to='/prices/more/seo'
                            className="prices__menu--link"
                            activeClassName="prices__menu--link--active"
                            // onClick={() => this.check()}
                        >
                            SEO-Оптимизация
                        </NavLink>
                    </div>
                    {/*<div className="prices__menu--title">*/}
                    {/*<div className="prices__menu--title--one">*/}
                    {/*Выберите подходящий вам вариант.*/}
                    {/*</div>*/}
                    {/*/!*<Link*!/*/}
                    {/*/!*to="/"*!/*/}
                    {/*/!*className="prices__menu--title--two"*!/*/}
                    {/*/!*>*!/*/}
                    {/*/!*Я не знаю какой вариант мне подходит.*!/*/}
                    {/*/!*</Link>*!/*/}
                    {/*</div>*/}
                </div>
                <Switch>
                    <Route path={`/prices/more/wp`}>
                        <MoreAll
                            moreInfo={moreInfo[0]}
                        />
                    </Route>
                    <Route path={`/prices/more/seo`}>
                        <MoreAll
                            moreInfo={moreInfo[1]}
                        />
                    </Route>
                </Switch>
            </React.Fragment>
        )
    }
}
