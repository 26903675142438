import React from 'react'
import {
    Switch,
    Route,
    NavLink,
    Link,
    Redirect,
    useLocation
} from "react-router-dom";
import MenuBottom from "../../components/MenuBottom";
import PricesAll from '../../components/Prices'

import './styles/Prices.scss'
import Preloader from "../../components/Preloader";
import More from "../../components/Prices/More";
import ContactUs from "../../components/Conact-us";
import MoreAll from "../../components/Prices/components/MoreAll";
import MoreMark from "../../components/Prices/components/MoreMark";

import ListExample from "../../components/ListExample";
import Promo from "../../components/Promo";
import Promo2 from "../../components/Promo2";

export default class Prices extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        }
        // this.check()
        // window.document.title = 'Цены'
    }

    // changeTitle = () => {
    //     window.document.title = this.state.title
    // }
    //
    // check = () => {
    //     window.document.title = 'Сайт-визитка'
    //     if (window.location.pathname === '/prices/card') {
    //         // this.setState({
    //         //     title: 'Сайт-визитка'
    //         // }, () => this.changeTitle())
    //         window.document.title = 'Сайт-визитка'
    //     }
    //     if (window.location.pathname === '/prices/landing-page') {
    //         // this.setState({
    //         //     title: 'Лендинг'
    //         // }, () => this.changeTitle())
    //         window.document.title = 'Лендинг'
    //     }
    //     if (window.location.pathname === '/prices/business-site') {
    //         // this.setState({
    //         //     title: 'Бизнес-сайт'
    //         // }, () => this.changeTitle())
    //         window.document.title = 'Бизнес-сайт'
    //     }
    //     if (window.location.pathname === '/prices/online-store') {
    //         // this.setState({
    //         //     title: 'Интернет-магазин'
    //         // }, () => this.changeTitle())
    //         window.document.title = 'Интернет-магазин'
    //     }
    // }

    componentDidMount() {
        window.scrollTo(0, 0);
        // setTimeout(() => {
        //     window.document.title = 'Цены'
        // }, 0)
        // document.title = this.props.titleTag && this.props.titleTag
        // this.check()
    }

    render() {
        let sections = [
            {
                name: 'Сайт-визитка',
                text: 'Небольшой сайт, для быстрого старта',
                oldPrice: '8 000 ₽',
                price: '5 000 ₽',
                sale: '38%',
                date: '1 — 3 дня',
                desc: 'Если Вы, только начинаете развивать свой собственный бизнес — сайт-визитка самый недорогой, но эффективный вид одностраничного сайта, чтобы привлечь клиентов. Сайт-визитка это: краткое представление о Вас или вашей компании, удобный инструмент для контекстной рекламы и хорошо подходит для тех, кто продает один товар или услугу.',
                dev: [
                    {
                        one: [
                            'Продающий дизайн.',
                            'Адаптивная верстка.',
                            'Сайт из 1 страницы (5 блоков).',
                            'Наполнение материалами (материалы предоставляются заказчиком и в электронном виде).',
                        ],
                        two: [
                            'Домен в подарок, стоимостью не более чем 10% от цены сайта.',
                            'SSL-сертификат (HTTPS), на 3 месяца.',
                            'Хостинг на 1  месяц бесплатно.',
                            '4 гб. места под сайт.',
                            `Корпоративная почта имя@имя-сайта.ru`,
                            `Система управления сайтом (CMS).`,
                            `Техническая поддержка 1 месяц.`,
                        ],
                        three: [
                            'SEO панель для продвижения сайта.',
                            'Установка счетчика посещаемости и карты кликов.',
                            'Регистрация в поисковых системах Google.com, Yandex.ru.',
                        ],
                    },
                ],
                href: '/prices/card',
                typeSend: 'cardVisit'
            },
            {
                name: 'Лендинг',
                text: 'Целевая страница с информацией об компании, услуге или товаре, состоящий из нескольких страниц',
                oldPrice: 'от 12 000 ₽',
                price: 'от 8 000 ₽',
                sale: '33%',
                date: '3 — 5 дня',
                desc: 'Посадочные страницы нужны для онлайн-ресурсов. Нарастить базу подписчиков, продать новый товар из интернет-магазина, создать онлайн-презентацию услуг компании: со всем этим справляется лендинг. Маркетологи и менеджеры по продажам используют целевые страницы по-разному: одни для наращивания продаж, другие для тестирования ниши и исследований. Лендинг для анонса мероприятия позволяет представить событие в лучшем свете и отследить конкретный канал продаж, лендинг для сегментации пользователей позволяет сконцентрироваться только на одной целевой группе и затрагивать их.',
                dev: [
                    {
                        one: [
                            'Продающий дизайн.',
                            'Адаптивная верстка.',
                            'Сайт из 3 страниц (до 15 блоков в сумме).',
                            'Наполнение материалами (материалы предоставляются заказчиком и в электронном виде).',
                            'Подключение резервного копирования данных.',
                            'Установка онлайн-чата.',
                        ],
                        two: [
                            'Домен в подарок, стоимостью не более чем 10% от цены сайта.',
                            'SSL-сертификат (HTTPS), на 3 месяца.',
                            'Хостинг на 2  месяц бесплатно.',
                            '4 гб. места под сайт.',
                            `Корпоративная почта имя@имя-сайта.ru`,
                            `Система управления сайтом (CMS).`,
                            `Техническая поддержка 2 месяц.`,
                        ],
                        three: [
                            'SEO панель для продвижения сайта.',
                            'Установка счетчика посещаемости и карты кликов.',
                            'Регистрация в поисковых системах Google.com, Yandex.ru.',
                            'Добавление организации в карты поисковиков: Google, Яндекс. (По желанию)',
                        ],
                    },
                ],
                href: '/prices/landing-page',
                typeSend: 'landingPage'
            },
            {
                name: 'Бизнес-Сайт',
                text: 'Полноценный инструмент по ведению бизнеса',
                oldPrice: 'от 18 000 ₽',
                price: 'от 14 000 ₽',
                sale: '22%',
                date: 'от 10 дней',
                desc: 'Бизнес сайт предоставляет не только уникальную возможность презентовать свою компанию в интернете, но и способ привлечение новых клиентов. Поэтому разработанный сайт, поможет не только успешно продвигаться в поисковых системах но и поможет расширить имеющуюся клиентскую базу, наладить удобный интерактивный обмен информацией с покупателями продукции, товара, работ или услуг и облегчить процесс взаимодействия с клиентами и поставщиками.',
                dev: [
                    {
                        one: [
                            'Продающий дизайн.',
                            'Адаптивная верстка.',
                            'Сайт из 5 типовых страницы.',
                            'Наполнение материалами (материалы предоставляются заказчиком и в электронном виде).',
                            'Подключение резервного копирования данных.',
                            'Установка онлайн-чата.',
                        ],
                        two: [
                            'Домен в подарок, стоимостью не более чем 5% от цены сайта.',
                            'SSL-сертификат (HTTPS), на 3 месяца.',
                            'Хостинг на 3  месяц бесплатно.',
                            '4 гб. места под сайт.',
                            `Корпоративная почта имя@имя-сайта.ru`,
                            `Система управления сайтом (CMS).`,
                            `Техническая поддержка 3 месяц.`,
                        ],
                        three: [
                            'SEO панель для продвижения сайта.',
                            'Установка счетчика посещаемости и карты кликов.',
                            'Регистрация в поисковых системах Google.com, Yandex.ru.',
                            'Добавление организации в карты поисковиков: Google, Яндекс. (По желанию)',
                        ],
                    },
                ],
                href: '/prices/business-site',
                typeSend: 'businessPage'
            },
            {
                name: 'Интернет-магазин',
                text: 'Продажа товаров и услуг автоматически, без участия продавцов',
                oldPrice: 'от 32 000 ₽',
                price: 'от 25 000 ₽',
                sale: '22%',
                date: 'от 14 дней',
                desc: 'Подходит тем, кому нужна «витрина» офф-лайн магазина — интерактивный веб-сайт, рекламирующий товар или услугу, принимающий заказы на покупку, предлагающий пользователю выбор варианта расчета, способа получения заказа и выписывающий счет на оплату. На сайте интернет-магазина обычно представлен подробный каталог товаров с ценами, на основе которого пользователь формирует свой заказ. Логика интернет-магазина выстраивается по принципам реального торгового зала. Иными словами, в любом интернет-магазине необходимым требованием является наличие структурированного каталога товаров (аналог полок в магазине), системы поиска по ключевым словам (аналог секций в торговом зале), корзины заказов (аналог реальной корзины, в которую покупатель складывает выбранные товары), разнообразия платежных систем, выбор способов и служб доставки.',
                dev: [
                    {
                        one: [
                            'Продающий дизайн.',
                            'Адаптивная верстка.',
                            'Сайт из 10 типовых страниц, в которые входит: кабинет клиента с историей заказов, каталог, карточка товара и корзина.',
                            'Система скидок и цен.',
                            'Фильтры и сортировка товаров.',
                            'Система доставки.',
                            'Наполнение материалами (материалы предоставляются заказчиком и в электронном виде).',
                            'Подключение резервного копирования данных.',
                            'Установка онлайн-чата.',
                        ],
                        two: [
                            'Домен в подарок, стоимостью не более чем 5% от цены сайта.',
                            'SSL-сертификат (HTTPS), на 3 месяца.',
                            'Хостинг на 3  месяц бесплатно.',
                            '4 гб. места под сайт.',
                            `Корпоративная почта имя@имя-сайта.ru`,
                            `Система управления сайтом (CMS).`,
                            `Техническая поддержка 3 месяц.`,
                        ],
                        three: [
                            'SEO панель для продвижения сайта.',
                            'Установка счетчика посещаемости и карты кликов.',
                            'Регистрация в поисковых системах Google.com, Yandex.ru.',
                            'Добавление организации в карты поисковиков: Google, Яндекс. (По желанию)',
                        ],
                    },
                ],
                href: '/prices/online-store',
                typeSend: 'storePage'
            },
            {
                name: 'Верстка',
                text: 'Верстка сайта по дизайну или макету',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 200 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если у Вас есть дизайн вашего будующего сайта или макет – обратившись к нам мы его "сверстаем" сделаем путем написания специального кода. Верстка определяет то, как сайт будет отображаться и работать в браузере.',
                href: '/prices/online-store',
                typeSend: ''
            },
            {
                name: 'WordPress',
                text: 'Работа с сайтом на WordPress',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 300 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если у Вас уже есть сайт в котором Вы используете CMS "WordPress" и вам необходимо его доработать или изменить, в том числе плагины в нем. Вам будет удобен этот вариант, так как Вам нужно изменить "Точечно" что-то на сайте.',
                href: '/prices/online-store',
                typeSend: ''
            },
            {
                name: 'Домен',
                text: 'Регистрация доменов для сайта',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 100 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если у Вас есть сайт и нет имени для него. Кратко: Регистрация домена - это процесс внесения записи о новом доменном имени в реестр зоны первого уровня.',
                href: '/prices/online-store',
                typeSend: ''
            },
            {
                name: 'Хостинг',
                text: 'Регистрация хостинга для сайта',
                // oldPrice: 'от 150 ₽ за час',
                price: 'от 100 ₽',
                // sale: '22%',
                date: 'По часово',
                desc: 'Если вы не знаете как и где нужно разместить сайт.',
                href: '/prices/online-store',
                typeSend: ''
            },
            {
                name: 'Дизайн сайта',
                text: 'Создание дизайна сайта',
                // oldPrice: 'от 32 000 ₽',
                price: 'от 3 000 ₽',
                // sale: '22%',
                date: 'от 2 дней',
                desc: '«Встречают по одежде» - это выражение актуально не только по отношению к людям. Интернет-сайты тоже встречаются именно по одежде, по внешнему виду - дизайну. Поэтому важно создать только качественные дизайн. Дизайн веб-сайта имеет большое значение в процессе его продвижения. Первый контакт посетителя с сайтом основан на визуальном восприятии страницы. Если внешний вид сайта будет приятным и интересным, то пользователь задержится здесь и захочет больше узнать о содержании веб-ресурса.',
                one: 'Создание',
                two: 'Уникальность',
                three: 'Реализация',
                dev: [
                    {
                        one: [
                            'Изучаем сферу деятельности компании.',
                            'Анализ конкурентов.',
                            'Анализ потребителя.',
                            'Генерируем идеи.',
                            'Создаем несколько вариантов.',
                            'Выбираем один из них вместе с вами.',
                        ],
                        two: [
                            'Грамотная навигация.',
                            'Акценты.',
                            'Понимаемый UI и UX.',
                            'Согласованность.',
                            `Легко читаемый текст`,
                            `Правильно сочетаемые подобранные цвета.`,
                        ],
                        three: [
                            'Создание 1 макета.',
                            'Внесем 2 правки.',
                            'Изучим вашу нишу.',
                            `Проанализируем конкурентов`,
                            `Выявим тренды.`,
                            `Правильно подберем шрифт.`,
                            `Правильно подберем цвет.`,
                        ],
                    },
                ],
                href: '/prices/design/site',
                typeSend: 'design'
            },
            {
                name: 'Логотип',
                text: 'Создание логотипа для вашего бренда, товара или услуги',
                // oldPrice: 'от 32 000 ₽',
                price: 'от 2 000 ₽',
                // sale: '22%',
                date: 'от 1 дня',
                desc: 'Цель логотипа – не просто привлечь внимание клиента, но и запомниться ему. Удачный логотип – это то, что позволяет любому человеку сразу, с первого же взгляда, понять, в какой сфере работает организация и каковы ее особенности.',
                one: 'Создание',
                two: 'Уникальность',
                three: 'Реализация',
                dev: [
                    {
                        one: [
                            'Изучаем сферу деятельности компании.',
                            'Анализ конкурентов.',
                            'Изучение потребителя.',
                            'Генерируем идеи.',
                            'Создаем несколько вариантов.',
                            'Выбираем один из них вместе с вами.',
                            'Тестируем и передаем проект клиенту.',
                        ],
                        two: [
                            'Изучение бренд-стратегии компании и направления ее дальнейшего развития.',
                            `Составление портрета потребителя.`,
                            `Выяление требований и желаний потребителя.`,
                        ],
                        three: [
                            'Разработаем 1 вариант.',
                            'Внесем 2 правки.',
                            'Изучим вашу нишу.',
                            `Проанализируем конкурентов`,
                            `Выявим тренды.`,
                            `Правильно подберем шрифт.`,
                            `Правильно подберем цвет.`,
                        ],
                    },
                ],
                href: '/prices/design/logo',
                typeSend: 'logo'
            },
            {
                name: 'Яндекс.Директ',
                text: 'Настройка Яндекс.Директа',
                // oldPrice: 'от 32 000 ₽',
                price: 'от 4 000 ₽',
                // sale: '22%',
                date: 'от 1 дня',
                desc: '«Директ» — это система, которая нужна, чтобы размещать контекстную и медийную рекламу на страницах «Яндекса» и его партнеров. Контекстные объявления, запущенные через этот сервис, транслируются пользователям, которые ищут похожие товары и услуги в интернете, а значит, больше других заинтересованы в покупке..',
                one: 'Создание',
                two: 'Реализация',
                three: 'Реализация',
                dev: [
                    {
                        one: [
                            'Подбор ключевых слов (до 20).',
                            'Согласование ключевых слов.',
                            'Определение уникальных преимуществ и написание 1 объявления.',
                            'Использование дополнительных преимуществ в объявлениях.',
                            'Техническая часть настройки и заливка на аккаунт.',
                        ],
                        two: [
                            'Определение направлений и группировка ключей.',
                            `Создаем объявления под каждую группу (1 группа) слов.`,
                            `Техническая часть настройки и заливка на аккаунт.`,
                        ],
                    },
                ],
                href: '/prices/online-store',
                typeSend: 'yd'
            },
            {
                name: 'Контекстная реклама',
                text: 'Контекстная реклама для сайта',
                // oldPrice: 'от 32 000 ₽',
                price: 'от 5 000 ₽',
                // sale: '22%',
                date: 'от 2 дня',
                desc: 'Контекстная реклама позволяет быстро привлечь на ваш сайт клиентов, которые ищут ваш товар или услуги. Десятки тысяч людей по всей России прямо сейчас ищут в Яндексе или в Google, к примеру, кафельную плитку или мебель на заказ, или гель-лак для ногтей или любой другой товар, который вы уже продаете.',
                one: 'Анализ',
                two: 'Разработка',
                three: 'Реализация',
                dev: [
                    {
                        one: [
                            'Изучение специфики отрасли.',
                            'Изучение целевой аудитории.',
                            'Расчет стоимости и прибыли с одной продажи.',
                            'Изучение конкурентов.',
                        ],
                        two: [
                            'Составления семантического ядра.',
                            `Создание продающих обьявлений (1 обьявление).`,
                            `Создание расширений РК.`,
                            `Поставновка целей рекламной кампании.`,
                            `Разработка плана рекламной кампании.`,
                        ],
                        three: [
                            'Настройка аккаунта Google Ads, Yandex.Direct.',
                            'Настройка таргетинга и геолокации.',
                            'Запуск рекламной кампании.',
                        ],
                    },
                ],
                href: '/prices/online-store',
                typeSend: 'context'
            },
        ];


        // console.log('sections', sections[0]);

        return (
            <React.Fragment>
                <Preloader
                    preloader={true}
                    text={'Выберите сайт и нажмите заказать.'}
                />
                <div className="prices">
                    {/*<div className="prices__block">*/}
                        {/*<div className="prices__block__title">*/}
                            {/*<div className="prices__block__title--title">*/}
                                {/*Цены*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*<div className="prices__block__title--desc">*/}
                                {/*Информация и цена каждого варианта*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div className="prices__block prices__block--center">
                        <div className="prices__block--container">
                            {/*<div className="prices__menu--title">*/}
                            {/*    <div className="prices__menu--title--one">*/}
                            {/*        Разработка:*/}
                            {/*    </div>*/}
                            {/*    /!*<Link*!/*/}
                            {/*    /!*    to="/"*!/*/}
                            {/*    /!*    className="prices__menu--title--two"*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*    Я не знаю какой вариант мне подходит.*!/*/}
                            {/*    /!*</Link>*!/*/}
                            {/*</div>*/}
                            <div className="prices__menu">
                                <NavLink
                                    to='/prices/dev'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Разработка
                                </NavLink>
                                <NavLink
                                    to='/prices/design'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Дизайн
                                </NavLink>
                                <NavLink
                                    to='/prices/seo'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Маркетинг
                                </NavLink>
                                {/*<NavLink*/}
                                {/*    to='/prices/more'*/}
                                {/*    className="prices__menu--link"*/}
                                {/*    activeClassName="prices__menu--link--active"*/}
                                {/*    // onClick={() => this.check()}*/}
                                {/*>*/}
                                {/*    Доп-Услуги*/}
                                {/*</NavLink>*/}
                            </div>
                        </div>
                    </div>
                    <Switch>
                        <Route path={`/prices/dev/card`}>
                            <PricesAll
                                menu={true}
                                sections={sections[0]}
                                title='Сайт-визитка'
                                more={true}
                            />
                            <ListExample
                                item={1}
                            />
                            <div style={{
                                fontSize: '0.8em',
                                color: '#757575',
                                padding: '0.1em 0 3rem 170px'
                            }}>
                                Примеры возможного сайта приведены выше. <br/>
                                Вы можете просмотреть приведенные выше примеры, щелкнув по определенному объекту.
                            </div>
                            <Promo/>
                            <Promo2/>
                        </Route>
                        <Route path={`/prices/dev/landing-page`}>
                            <PricesAll
                                menu={true}
                                sections={sections[1]}
                                title='Лендинг'
                                more={true}
                            />
                            <ListExample
                                item={2}
                            />
                            <div style={{
                                fontSize: '0.8em',
                                color: '#757575',
                                padding: '0.1em 0 3rem 170px'
                            }}>
                                Примеры возможного сайта приведены выше. <br/>
                                Вы можете просмотреть приведенные выше примеры, щелкнув по определенному объекту.
                            </div>
                            <Promo/>
                            <Promo2/>
                        </Route>
                        <Route path={`/prices/dev/business-site`}>
                            <PricesAll
                                menu={true}
                                sections={sections[2]}
                                title='Бизнес-сайт'
                                more={true}
                            />
                            <ListExample
                                item={'0'}
                            />
                            <div style={{
                                fontSize: '0.8em',
                                color: '#757575',
                                padding: '0.1em 0 3rem 170px'
                            }}>
                                Примеры возможного сайта приведены выше. <br/>
                                Вы можете просмотреть приведенные выше примеры, щелкнув по определенному объекту.
                            </div>
                            <Promo/>
                            <Promo2/>
                        </Route>
                        <Route path={`/prices/dev/online-store`}>
                            <PricesAll
                                menu={true}
                                sections={sections[3]}
                                title='Интернет-магазин'
                            />
                            <ListExample
                                item={3}
                            />
                            <div style={{
                                fontSize: '0.8em',
                                color: '#757575',
                                padding: '0.1em 0 3rem 170px'
                            }}>
                                Примеры возможного сайта приведены выше. <br/>
                                Вы можете просмотреть приведенные выше примеры, щелкнув по определенному объекту.
                            </div>
                            <Promo/>
                            <Promo2/>
                        </Route>
                        <Route path={`/prices/dev/more`}>
                            <>
                                <PricesAll
                                    menu={true}
                                    sections={sections[4]}
                                    title='Доп. Услуги'
                                />
                                <PricesAll
                                    menu={false}
                                    sections={sections[5]}
                                    title='Доп. Услуги'
                                />
                                <PricesAll
                                    menu={false}
                                    sections={sections[6]}
                                    title='Доп. Услуги'
                                />
                                <PricesAll
                                    menu={false}
                                    sections={sections[7]}
                                    title='Доп. Услуги'
                                />
                            </>
                        </Route>
                        <Route path={`/prices/design/site`}>
                            <MoreAll
                                menu={true}
                                sections={sections[8]}
                                title='Дизайн сайта'
                            />
                        </Route>
                        <Route path={`/prices/design/logo`}>
                            <MoreAll
                                menu={true}
                                sections={sections[9]}
                                title='Логотип'
                            />
                        </Route>
                        <Route path={`/prices/seo/yd`}>
                            <MoreMark
                                menu={true}
                                sections={sections[10]}
                                title='Яндекс. Директ'
                            />
                        </Route>
                        <Route path={`/prices/seo/context`}>
                            <MoreMark
                                menu={true}
                                sections={sections[11]}
                                title='Контекстная реклама'
                            />
                        </Route>
                        <Route exact path={`/prices`}>
                            <Redirect to="/prices/dev/card"/>
                        </Route>
                        <Route exact path={`/prices/dev`}>
                            <Redirect to="/prices/dev/card"/>
                        </Route>
                        <Route exact path={`/prices/design`}>
                            <Redirect to="/prices/design/site"/>
                        </Route>
                        <Route exact path={`/prices/seo`}>
                            <Redirect to="/prices/seo/yd"/>
                        </Route>
                    </Switch>
                    <ContactUs
                        title={true}
                        titleText={'Если нужно больше – напишите нам'}
                    />
                </div>
                <MenuBottom/>
            </React.Fragment>
        )
    }
}
