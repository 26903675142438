import React from 'react'
import d from '../../assets/images/landlix-d.svg';
import l from '../../assets/images/landlix-l.svg';

import './styles/Preloader.scss'

export default class Preloader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            preloader: this.props.preloader
        }
    }

    setOffPreloader() {
        this.setState({
            preloader: false
        });
    }

    componentDidMount() {

    }

    render() {
        setTimeout(() => {this.setOffPreloader()}, 2000);
        return (
            <div
                className={`preloader ${this.state.preloader ? 'preloader--on' : 'preloader--off'}`}
            >
                <img src={d}/>
                <img src={l}/>
                {
                    this.props.text &&
                    <div style={{position: 'absolute', top: '60%'}}>
                        {this.props.text}
                    </div>
                }
                <div className="preloader--text">
                    Студия по созданию сайтов.<br/>
                    landlix.ru
                </div>
                {/*<svg className="vall"*/}
                     {/*xmlns="http://www.w3.org/2000/svg"*/}
                     {/*xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                     {/*viewBox="0 24 150 28"*/}
                     {/*preserveAspectRatio="none">*/}
                    {/*<defs>*/}
                        {/*<path id="gentle-wave"*/}
                              {/*d="M-160 44c30 0*/}
                                {/*58-18 88-18s*/}
                                {/*58 18 88 18*/}
                                {/*58-18 88-18*/}
                                {/*58 18 88 18*/}
                                {/*v44h-352z"/>*/}
                    {/*</defs>*/}
                    {/*<g className="parallax">*/}
                        {/*<use xlinkHref="#gentle-wave" x="50" y="0" fill="#1f5d96"/>*/}
                        {/*<use xlinkHref="#gentle-wave" x="50" y="3" fill="#5393ce"/>*/}
                        {/*<use xlinkHref="#gentle-wave" x="50" y="6" fill="#b5dafd"/>*/}
                    {/*</g>*/}
                {/*</svg>*/}
            </div>
        )
    }
}
