import React from 'react'

import './styles/MenuBottom.scss'

export default class MenuBottom extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            menuActive: 0
        }
    }

    listenScrollEventNew = e => {
        let head = document.querySelector('.head');
        if (!head) return;
        const cliVIe = window.screen.availHeight;
        const cliHgt = (head.scrollTop + cliVIe) - 200;
        const scroll = window.scrollY;
        if (scroll >= cliHgt) {
            if (head) {
                this.setState({
                    menu: true
                })
            }
        }
        if (scroll <= cliHgt) {
            if (head) {
                this.setState({
                    menu: false
                })
            }
        }
        if (scroll >= cliHgt && scroll <= (cliHgt + cliHgt * 5.8 - 200)) {
            this.setState({
                menuActive: 1
            });
        }
        if (scroll >= (cliHgt + cliHgt * 4.2)) {
            this.setState({
                menuActive: 2
            });
        }
        if (scroll >= (cliHgt + cliHgt * 6.4)) {
            this.setState({
                menuActive: 3
            });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEventNew);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScrollEventNew);
    }

    render() {

        let { menu, menuActive } = this.state;
        return (
            <React.Fragment>
                <div className={`menu ${menu ? 'menu--on' : 'menu--off'}`}>
                    <div className="menu__block">
                        <a
                            href="#head"
                            className={`menu__block--link ${menuActive === 0 ? 'menu__block--link--active' : ''}`}
                        >
                            На верх
                        </a>
                        <a
                            href="#about-us"
                            className={`menu__block--link ${menuActive === 1 ? 'menu__block--link--active' : ''}`}
                        >
                            О нас
                        </a>
                        <a
                            href="#cost"
                            className={`menu__block--link ${menuActive === 2 ? 'menu__block--link--active' : ''}`}
                        >
                            Цены
                        </a>
                        <a
                            href="#contact-us"
                            className={`menu__block--link ${menuActive === 3 ? 'menu__block--link--active' : ''}`}
                        >
                            Контакты
                        </a>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
