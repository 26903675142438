import React from 'react'
import './styles/About-us.scss'
// import imOne from '../../assets/images/escalator-4907329.jpg'
// import imOne from '../../assets/images/progress__monochromatic.svg'
// import imOne from '../../assets/images/analytics_process_monochromatic.svg'
// import imOneAnim from '../../assets/images/one-block--anim.svg'

export default class AboutUs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="about-us" id="about-us">
                    {/*<div className="about-us__back--one"/>*/}
                    <div className="about-us__block">
                        <div className="about-us__block--img--full">
                            {/*<img src={imOne}/>*/}
                            {/*<img src={imOneAnim}/>*/}
                        </div>
                        <div className="about-us__block--text">
                            <div className="about-us--title">
                                <p>
                                    О нас
                                </p>
                                <p>
                                    Мы сделаем все идеально
                                </p>
                            </div>
                            <p>При разработке целевых страниц мы используем только лучшие оптимизированные технологии и проверенные поведенческие модели.<br/>
                            Мы создаем один из лучших инструментов, чтобы эффективно представить ваш продукт или услугу на понятном языке для ваших будущих клиентов.<br/></p>
                            {/*<button>*/}
                                {/*Подробнее*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
