import React from 'react'

import './styles/Main.scss'

import imgOne from "../../assets/images/block-img-one.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";
import Benefits from "../../components/Benefits";
// import imTwo from "../../assets/images/comp_process.svg";
import imTwo from "../../assets/images/campaign_launch_monochromatic.svg";
import Preloader from "../../components/Preloader";

export default class About extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            infoComp: {
                preTitle: 'О нас',
                title: 'Одни из лучших',
                desc: 'Высококвалифицированная команда развивает каждый проект, делая его и последующии проекты лучшим на рынке. С нами успешно продолжают сотрудничать после релиза проекта. Мы используюем только лучшие и передовые технологии. Заказать у нас разработку сайта — это увеличть конверсию продукта. Благодаря индивидуальному подходу и высокому качеству работы нашей команды — мы сумарно увеличили конверсию нашим заказчикам на более чем 10 000%.',
                img: imTwo,
                data: '/about-us'
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = this.props.titleTag && this.props.titleTag
    }

    render() {
        let { menu, menuActive } = this.state;
        return (
            <React.Fragment>
                <Preloader
                    preloader={true}
                    text={'Мы сделаем все в лучшем виде.'}
                />
                <div className="about">
                    {/*<div className="about__container">*/}
                        {/*<div className="about__block">*/}
                            {/*<div className="about__block__box">*/}
                                {/*<div className="about__block__box__title">*/}
                                    {/*Динамически развивающиеся копания*/}
                                {/*</div>*/}
                                {/*<div className="about__block__box__desc">*/}
                                    {/*Высококвалифицированная команда развивает каждый проект, делая его и последующии проекты лучшим на рынке.*/}
                                    {/*С нами успешно продолжают сотрудничать после релиза проекта.*/}
                                    {/*Каждый в нашей команде имеет опыт работы в своей сфере более 5 лет.*/}
                                    {/*У нас работают только лучшие специалисты и мы используюем только лучшие и передовые технологии.*/}
                                    {/*Заказать у нас разработку сайта — это увеличть конверсию продукта.*/}
                                    {/*Благодаря индивидуальному подходу и высокому качеству работы нашей команды — мы сумарно увеличили конверсию нашим заказчикам на более чем 10 000%.<br/>*/}
                                    {/**/}
                                {/*</div>*/}
                            {/*</div>*/}
                            {/*/!*<div className="about__block__box">*!/*/}
                                {/*/!*<div className="about__block__box--img">*!/*/}
                                    {/*/!*<img src={imgOne}/>*!/*/}
                                {/*/!*</div>*!/*/}
                            {/*/!*</div>*!/*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <Benefits
                        data={this.state.infoComp}
                    />
                </div>
            </React.Fragment>
        )
    }
}
