import React from 'react'

import './styles/Main.scss'

import email from "../../assets/images/email-circ.svg";
import vk from "../../assets/images/vk.svg";
import telegram from "../../assets/images/telegram.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import qrEmail from "../../assets/images/qr/qr-code-email.svg";
import qrTelegram from "../../assets/images/qr/qr-code-telegram.svg";
import qrWhatsapp from "../../assets/images/qr/qr-code-whatsapp.svg";
import qrVk from "../../assets/images/qr/qr-code-vk.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";
import Benefits from "../../components/Benefits";
// import imTwo from "../../assets/images/comp_process.svg";
import imTwo from "../../assets/images/handshake_monochromatic.svg";
import Preloader from "../../components/Preloader";
import {Link} from "react-router-dom";

export default class About extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            infoComp: {
                title: 'Контакты',
                desc: <div className="benefits--contact--container">
                    <h3>
                        Вы можете с нами связаться через:
                    </h3>
                    <div className="benefits--contact--container--block">
                        <div className="benefits--contact--block">
                            <div className="benefits--contact--box">
                                <img className="benefits--contact--box--img--qr" src={qrEmail}/>
                            </div>
                            <a
                                className="benefits--contact--box"
                                href="mailto:sales@landlix.ru"
                                target="_blank"
                            >
                                <div className="benefits--contact--box--img">
                                    <img src={email}/>
                                </div>
                                <div className="benefits--contact--box--item">
                                    Email
                                </div>
                            </a>
                        </div>
                        <div className="benefits--contact--block">
                            <div className="benefits--contact--box">
                                <img className="benefits--contact--box--img--qr" src={qrVk}/>
                            </div>
                            <a
                                className="benefits--contact--box"
                                href="https://vk.com/im?sel=-18263558"
                                target="_blank"
                            >
                                <div className="benefits--contact--box--img">
                                    <img src={vk}/>
                                </div>
                                <div className="benefits--contact--box--item">
                                    VK
                                </div>
                            </a>
                        </div>
                        <div className="benefits--contact--block">
                            <div className="benefits--contact--box">
                                <img className="benefits--contact--box--img--qr" src={qrTelegram}/>
                            </div>
                            <a
                                className="benefits--contact--box"
                                href="https://t.me/landlix"
                                target="_blank"
                            >
                                <div className="benefits--contact--box--img">
                                    <img src={telegram}/>
                                </div>
                                <div className="benefits--contact--box--item">
                                    Telegram
                                </div>
                            </a>
                        </div>
                        <div className="benefits--contact--block">
                            <div className="benefits--contact--box">
                                <img className="benefits--contact--box--img--qr" src={qrWhatsapp}/>
                            </div>
                            <a
                                className="benefits--contact--box"
                                href="https://wa.me/message/W7VPE6VMJKEFD1"
                                target="_blank"
                            >
                                <div className="benefits--contact--box--img">
                                    <img src={whatsapp}/>
                                </div>
                                <div className="benefits--contact--box--item">
                                    WhatsApp
                                </div>
                            </a>
                        </div>
                    </div>
                    <p>
                        Чтобы открыть чат – нажмите на кнопку с названием чата.<br/>
                        Или наведите сканером QR-Кода с телефона:<br/>
                        iOS – Камера<br/>
                        Android – <a className="footer__block__menu--item" href="https://play.google.com/store/apps/details?id=com.gamma.scan&hl=en_US&gl=US" target="_blank">Ссылка на сканер QR Кода</a><br/>
                    </p>
                </div>,
                img: imTwo,
                data: '/about-us'
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let { menu, menuActive } = this.state;
        return (
            <React.Fragment>
                <Preloader
                    preloader={true}
                    text={'Мы ответим в течении 10 минут.'}
                />
                <div
                    className="about"
                    style={{
                        paddingTop: '10vw'
                    }}
                >
                    {/*<div className="about__container">*/}
                        {/*<div className="about__block">*/}
                            {/*<div className="about__block__box">*/}
                                {/*<div className="about__block__box__title">*/}
                                    {/*Динамически развивающиеся копания*/}
                                {/*</div>*/}
                                {/*<div className="about__block__box__desc">*/}
                                    {/*Высококвалифицированная команда развивает каждый проект, делая его и последующии проекты лучшим на рынке.*/}
                                    {/*С нами успешно продолжают сотрудничать после релиза проекта.*/}
                                    {/*Каждый в нашей команде имеет опыт работы в своей сфере более 5 лет.*/}
                                    {/*У нас работают только лучшие специалисты и мы используюем только лучшие и передовые технологии.*/}
                                    {/*Заказать у нас разработку сайта — это увеличть конверсию продукта.*/}
                                    {/*Благодаря индивидуальному подходу и высокому качеству работы нашей команды — мы сумарно увеличили конверсию нашим заказчикам на более чем 10 000%.<br/>*/}
                                    {/**/}
                                {/*</div>*/}
                            {/*</div>*/}
                            {/*/!*<div className="about__block__box">*!/*/}
                                {/*/!*<div className="about__block__box--img">*!/*/}
                                    {/*/!*<img src={imgOne}/>*!/*/}
                                {/*/!*</div>*!/*/}
                            {/*/!*</div>*!/*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <Benefits
                        data={this.state.infoComp}
                        blockLeft={false}
                    />
                </div>
            </React.Fragment>
        )
    }
}
