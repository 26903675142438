import React from 'react'

import classNames from 'classnames';
import SendEmail from "../../components/SendEmail";
import './styles/Head.scss'
import img from '../../assets/images/rocksss2.svg'
import {Link} from "react-router-dom";

function animate(options) {

    let start = performance.now();

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / options.duration;
        if (timeFraction > 1) timeFraction = 1;

        let progress = options.timing(timeFraction);
        options.draw(progress);

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        }

    });
}

function bounce(timeFraction) {
    for (let a = 0, b = 1, result; 1; a += b, b /= 2) {
        if (timeFraction >= (7 - 4 * a) / 11) {
            return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2)
        }
    }
}

const typingList = [
    {
        text: `Сайта-визитки`,
    },
    {
        text: `Landing page`,
    },
    {
        text: `Бизнес-сайта`,
    },
    {
        text: `Online-магазина`,
    },
    {
        text: `Чат ботов`,
    },
];


class Typing extends React.PureComponent {
    _mounted = false;

    state = {
        index: 1
    };

    animateText() {
        let listItem = typingList[this.state.index];
        let text = listItem.text;

        let to = text.length,
            from = 0;

        this.cursor.classList.add('text-typing--cursor--stop');

        animate({
            duration: 1800,
            timing: function (timeFraction) {
                return timeFraction;
            },
            draw: (progress) => {
                if (this._mounted) {
                    let result = (to - from) * progress + from;
                    this.text.innerText = text.substr(0, Math.ceil(result));
                    if (progress === 1) {
                        this.cursor.classList.remove('text-typing--cursor--stop');
                        this.timer = setTimeout(() => {
                            this.hideText();
                        }, 2e3)
                    }
                }
            }
        });
    };

    hideText() {
        let {index} = this.state;
        let listItem = typingList[index];
        let text = listItem.text;

        let to = 0,
            from = text.length;
        this.cursor.classList.add('text-typing--cursor--stop');

        animate({
            duration: 800,
            timing: function (timeFraction) {
                return timeFraction;
            },
            draw: (progress) => {
                if (this._mounted) {
                    let result = (to - from) * progress + from;
                    this.text.innerText = text.substr(0, Math.ceil(result));
                    if (progress === 1) {
                        this.cursor.classList.remove('text-typing--cursor--stop');
                        // this.author.innerText = '';
                        this.timer = setTimeout(() => {
                            this.setState({index: index === typingList.length - 1 ? 0 : index + 1}, () => {
                                this.animateText();
                            })
                        }, 2e3)
                    }
                }
            }
        });
    }

    componentDidMount() {
        this._mounted = true;
        this.animateText();
    }

    componentWillUnmount() {
        this._mounted = false;
        if (this.timer)
            clearTimeout(this.timer);
    }

    renderText(data){
        return (
            <React.Fragment>
                <span ref={e => this.text = e}>

                </span>
                <div className="text-typing--cursor--change">
                    <div
                        ref={e => this.cursor = e}
                        className={classNames({
                            'text-typing--cursor': true,
                            'text-typing--cursor--stop': false,
                        })}
                    />
                    <div
                        className='text-typing--author'
                        ref={e => this.author = e}
                    >
                        {data.author}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        let data = typingList[this.state.index];

        return <div className="text-typing">
            {data.position === 'before' && <React.Fragment>
                {this.renderText(data)}
                <br/>
            </React.Fragment>}
            <b>
            <span>
            </span>
            </b>
            {data.position !== 'before' && this.renderText(data)}
        </div>
    }
}

export default class HeadNew extends React.Component{
    constructor(props) {
        super(props);
    }

    listenScrollEvent = e => {
        let head = document.querySelector('.head-new');
        if (!head) return;
        const cliHgt = head.scrollTop;
        const cliHgtTwo = head.clientHeight + (head.clientHeight / 4);
        const scroll = window.scrollY;
        let parallax1 = (((scroll - (cliHgt / 2.5)) / 6) * -1);
        let parallax2 = (((scroll - (cliHgt / 2.5)) / 2) * -1);
        let parallax3 = (((scroll - (cliHgt / 2.5)) / 1.5) * -1);
        let parallax4 = (((scroll - (cliHgt / 2.5)) / 4) * -1);
        if (scroll <= cliHgt) {
            if (head) {
                document.querySelector('.parallax-one').style.transform = `translateY(${parallax1}px)`;
                // document.querySelector('.parallax-two').style.transform = `translateY(${parallax2}px)`;
                // document.querySelector('.parallax-three').style.transform = `translateY(${parallax3}px)`;
                // document.querySelector('.parallax-four').style.transform = `translateY(${parallax4}px)`;
            }
        }
        if (scroll <= cliHgtTwo) {
            document.querySelector('.parallax-one').style.transform = `translateY(${parallax1}px)`;
            // document.querySelector('.parallax-two').style.transform = `translateY(${parallax2}px)`;
            // document.querySelector('.parallax-three').style.transform = `translateY(${parallax3}px)`;
            // document.querySelector('.parallax-four').style.transform = `translateY(${parallax4}px)`;
        }
    };

    componentDidMount() {
        if (window.location.pathname === '/') {
            window.addEventListener('scroll', this.listenScrollEvent);
        }
    }

    componentWillUnmount() {
        if (window.location.pathname === '/') {
            window.removeEventListener('scroll', this.listenScrollEvent);
        }
    }

    render() {
        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSend = node)}
                />
                <div className="head-new">
                    <div className="head-new__container">
                        <div className="head-new__container--back parallax-one">
                            <img src={img} alt=""/>
                        </div>
                        <div className="head-new__container--box">
                            <div className="head-new__container--box--one">
                                Студия по созданию
                            </div>
                            <div className="head-new__container--box--two">
                                <Typing/>
                            </div>
                            <div className="head-new__container--box--three">
                                Мы создаем один из лучших инструментов, чтобы эффективно представить ваш продукт или услугу на понятном языке – для ваших будущих клиентов.<br/>
                                Мы разрабатываем сайты, создаем дизайны и продвигаем в топ.
                            </div>
                            <div
                                className="head-new__container--box--four"
                                onClick={() => {
                                    this.modalSend.open()
                                }}
                            >
                                Заказать
                            </div>
                            <Link
                                className="head-new__container--box--five"
                                to="/prices"
                            >
                                Подробнее
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
