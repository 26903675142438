import React from 'react'
import Head from "../../components/Head";
import Cost from "../../components/Cost";
import AboutUs from "../../components/About-us";
import MenuBottom from "../../components/MenuBottom";
import HowWork from "../../components/How-work";
import Benefits from "../../components/Benefits";
import Services from "../../components/Services";
import ContactUs from "../../components/Conact-us";
import ListSite from "../../components/ListSite";

import './styles/Main.scss'
import imOne from '../../assets/images/business_conference__monochromatic.svg'
// import imOne from '../../assets/images/Business_SVG.svg'
import imTwo from '../../assets/images/data_analytics__monochromatic.svg'
import axios from 'axios';
import TwoBlock from "../../components/TwoBlock";
import Preloader from "../../components/Preloader";
import HeadNew from "../../components/HeadNew";
import Promo from "../../components/Promo";
import Promo2 from "../../components/Promo2";
import WeEmail from "../../components/WeEmail";
import HelpEmail from "../../components/HelpEmail";

export default class Main extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            infoAbout: {
                title: 'Сайт под ключ',
                preTitle: 'О нас',
                desc: 'Перед началом мы узнаем всю информацию у Вас, обсуждаем и приходим , после чего мы обсуждаем с Вами дизайн, если он необходим, далее мы создаем несколько макетов вашего продукта/услуги. Исходя из вашей информации мы',
                img: imOne,
                data: '/about-us'
            },
            infoBenefits: {
                title: 'Преимущества сотрудничества',
                preTitle: 'О нас',
                desc: 'Задачи исходят из требований клиента. Учет бизнес-контекста каждого конкретного проекта или программы проектов для нас преимущественно.\n Мы ценим время наших клиентов.',
                img: imOne,
                data: '/about-us'
            },
            infoComp: {
                title: 'Динамично развивающаяся компания',
                preTitle: 'О нас',
                desc: 'Высококвалифицированная команда развивает каждый проект, делая его и последующии проекты лучшим на рынке. С нами успешно продолжают сотрудничать после релиза проекта. Мы используюем только лучшие и передовые технологии. Заказывая у нас разработку сайта — Вы успешно увеличиваете конверсию продукта. Благодаря индивидуальному подходу и высокому качеству работы нашей команды — мы сумарно увеличили конверсию нашим заказчикам на более чем 10 000%.',
                img: imTwo,
                data: '/about-us'
            }
        }
    }

    getTest() {
        // function json(response) {
        //     return response.json()
        // }
        // fetch('http://localhost:4005/email',
        //     {
        //         method: 'get',
        //         mode: 'no-cors',
        //         // cache: 'no-cache',
        //         // credentials: "same-origin",
        //         headers: {
        //             'Accept': 'application/*',
        //             'Content-Type': 'application/*; charset=utf-8',
        //         },
        //     }
        // )
        //     // .then(json)
        //     .then((data) => {
        //         console.log('Request succeeded with JSON response', data);
        //     })
        //     .then((response) => {
        //         console.log(response);
        //         response.json().then((data) => {
        //             console.log(data);
        //         });
        //     })
        //     // .then(function (data) {
        //     //     console.log('Request succeeded with JSON response', data);
        //     // })
        //     // .catch(function (error) {
        //     //     console.log('Request failed', error);
        //     // });
        //     .catch(function(err) {
        //         console.log('Fetch Error :-S', err);
        //     });
        // axios.get('http://localhost:4003/email')
        //     .then((resp) => {
        //         console.log('Request succeeded with JSON response', resp);
        //     })
    }

    componentDidMount() {
        document.title = this.props.titleTag && this.props.titleTag
        window.scrollTo(0, 0);
    }

    render() {
        let { menu, menuActive } = this.state;
        return (
            <React.Fragment>
                <Preloader
                    preloader={true}
                    text={'Сайт для Вашего бизнеса за 5 000 рублей.'}
                />
                {/*<Head/>*/}
                <HeadNew/>
                <AboutUs/>
                <Cost
                    section1={true}
                />
                <Promo/>
                <Promo2/>
                {
                    window.outerWidth > 1024 && <HowWork/>
                }
                <WeEmail/>
                <ListSite/>
                {/*<TwoBlock/>*/}
                <Cost
                    section2={true}
                />
                <HelpEmail/>
                <Benefits
                    data={this.state.infoComp}
                    background={true}
                />
                <Cost
                    section3={true}
                />
                <HelpEmail/>
                <Benefits
                    data={this.state.infoBenefits}
                    left={true}
                    background={true}
                />
                {/*<Services/>*/}
                <ContactUs
                    title={true}
                    titleText={'Обратная связь'}
                />
                <MenuBottom/>
            </React.Fragment>
        )
    }
}
