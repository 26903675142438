import React from 'react'
import './styles/Cost.scss'
import { Link } from "react-router-dom";

export default class Cost extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            s1: [
                {
                    name: 'Сайт Визитка',
                    price: '5 000 ₽',
                    date: '1 — 3 дня',
                    old: '8 000 ₽',
                    desc: [
                        'Продающий дизайн',
                        'Одностраничный сайт (до 5 блоков)',
                        'Адаптивная верстка',
                        'CMS система управления сайтом',
                        'Предоставление хостинга на 1 месяц',
                        'Доменное имя в зоне .ru/ .рф/ и др.',
                        'Создание корпоративной почты имя@имя- сайта.ru',
                        'Наполнение сайта (информацию предоставляет заказчик)',
                        'Регистрация в поисковых системах',
                        'Установка счетчика посещаемости и карты кликов',
                        'Техническая поддержка – 1 месяц',
                        'Предоставление SSL-сертификата (на 3 месяца)',
                    ],
                    info: 'Небольшой сайт, для быстрого старта',
                    href: '/prices/dev/card'
                },
                {
                    name: 'Лендинг',
                    price: <div><b>от</b> 8 000 ₽</div>,
                    date: '3 — 5 дня',
                    old: '12 000 ₽',
                    desc: [
                        'Продающий дизайн',
                        'Одностраничный сайт (до 15 блоков)',
                        'Адаптивная верстка',
                        'CMS система управления сайтом',
                        'Предоставление хостинга на 2 месяц',
                        'Доменное имя в зоне .ru/ .рф/ и др.',
                        'Создание корпоративной почты имя@имя- сайта.ru',
                        'Наполнение сайта (информацию предоставляет заказчик)',
                        'Регистрация в поисковых системах',
                        'Добавление организации в карты поисковиков(Google, Яндекс)',
                        'Установка счетчика посещаемости и карты кликов',
                        'Техническая поддержка – 2 месяц',
                        'Резервное копирование данных',
                        'Предоставление SSL-сертификата (на 3 месяца)',
                        'Установка онлайн-чата',
                    ],
                    info: 'Целевая страница с информацией об компании, услуге или товаре, состоящий из нескольких страниц.',
                    href: '/prices/dev/landing-page'
                },
                {
                    name: 'Бизнес-Сайт',
                    price: <div><b>от</b> 14 000 ₽</div>,
                    date: 'от 10 дней',
                    old: '18 000 ₽',
                    desc: [
                        'Продающий дизайн',
                        'Адаптивная верстка',
                        'CMS система управления сайтом',
                        'Предоставление хостинга на 3 месяц',
                        'Доменное имя в зоне .ru/ .рф/ и др.',
                        'Создание корпоративной почты имя@имя-сайта.ru',
                        'Наполнение сайта (информацию предоставляет заказчик)',
                        'Регистрация в поисковых системах',
                        'Добавление организации в карты поисковиков(Google, Яндекс)',
                        'Установка счетчика посещаемости и карты кликов',
                        'Техническая поддержка – 3 месяц',
                        'Резервное копирование данных',
                        'Предоставление SSL-сертификата (на 3 месяца)',
                        'Установка онлайн-чата',
                    ],
                    info: 'Бизнес сайт предоставляет не только уникальную возможность презентовать свою компанию в интернете, но и способ привлечение новых клиентов.',
                    href: '/prices/dev/business-site'
                },
                {
                    name: 'Интернет-магазин',
                    price: <div><b>от</b> 25 000 ₽</div>,
                    date: 'от 10 дней',
                    old: '30 000 ₽',
                    desc: [
                        'Продающий дизайн',
                        'Сайт с каталогом, карточкой товара и корзиной',
                        'Адаптивная верстка',
                        'CMS система управления сайтом',
                        'Предоставление хостинга на 3 месяц',
                        'Доменное имя в зоне .ru/ .рф/ и др.',
                        'Создание корпоративной почты имя@имя-сайта.ru',
                        'Наполнение сайта (информацию предоставляет заказчик) – 10 страниц в подарок',
                        'Настраиваемая система скидок и цен',
                        'Настраиваемые фильтры и сортировка товаров',
                        'Настраиваемая доставка',
                        'Импорт/Экспорт товаров',
                        'Кабинет клиента с историей заказов',
                        'Регистрация в поисковых системах',
                        'Добавление организации в карты поисковиков(Google, Яндекс)',
                        'Установка счетчика посещаемости и карты кликов',
                        'Техническая поддержка – 3 месяц',
                        'Резервное копирование данных',
                        'Предоставление SSL-сертификата (на 3 месяца)',
                        'Установка онлайн-чата',
                    ],
                    info: '«Витрина» офф-лайн магазина — интерактивный веб-сайт, рекламирующий товар или услугу, принимающий заказы на покупку, предлагающий пользователю выбор варианта расчета, способа получения заказа и выписывающий счет на оплату.',
                    href: '/prices/dev/online-store'
                }
            ],
            s2: [
                {
                    name: 'Верстка',
                    price: <div><b>от</b> 200 ₽</div>,
                    date: 'По часово',
                    info: 'Верстка сайта по дизайну или макету.',
                    href: '/prices/dev/more'
                },
                {
                    name: 'WordPress',
                    price: <div><b>от</b> 300 ₽</div>,
                    date: 'По часово',
                    info: 'Работа с сайтом на WordPress.',
                    href: '/prices/dev/more'
                },
                {
                    name: 'Домен',
                    price: <div><b>от</b> 100 ₽</div>,
                    date: 'По часово',
                    info: 'Регистрация доменов для сайта.',
                    href: '/prices/dev/more'
                },
                {
                    name: 'Хостинг',
                    price: <div><b>от</b> 100 ₽</div>,
                    date: 'По часово',
                    info: 'Регистрация хостинга для сайта.',
                    href: '/prices/dev/more'
                }
            ],
            s3: [
                {
                    name: 'Дизайн сайта',
                    price: <div><b>от</b> 3 000 ₽</div>,
                    date: 'от 2 дней',
                    info: 'Создание дизайна сайта.',
                    href: '/prices/design/site'
                },
                {
                    name: 'Логотип',
                    price: <div><b>от</b> 2 000 ₽</div>,
                    date: 'от 1 дня',
                    info: 'Создание логотипа для вашего бренда, товара или услуги.',
                    href: '/prices/design/logo'
                },
                {
                    name: 'Контекстная реклама',
                    price: <div><b>от</b> 5 000 ₽</div>,
                    date: 'от 2 дней',
                    info: 'Контекстная реклама позволяет быстро привлечь на ваш сайт клиентов.',
                    href: '/prices/seo/context'
                },
            ]
        }
    }

    render() {
        let sections
        if (this.props.section1) {
            sections = this.state.s1
        } else if (this.props.section2) {
            sections = this.state.s2
        } else if (this.props.section3) {
            sections = this.state.s3
        }

        return (
            <React.Fragment>
                <div className="cost" id="cost">
                    <div className="cost--title">
                        <p>Чтобы посмотреть все что входит в тариф, нажмите подробнее в тарифе</p>
                        <p>Услуги</p>
                    </div>
                    <div className="cost__container">
                        <div className="cost__block">
                            {
                                sections.map((item, index) => {
                                    return (
                                    <div className="cost__block--container" key={index}>
                                        <div className="cost__block--container__tariff">
                                            <div className="cost__block--container__tariff--name">
                                                Тариф
                                            </div>
                                            <div className="cost__block--container__tariff--selection">
                                                {item.name}
                                            </div>
                                        </div>
                                        {/*<div className="cost__block--container__description">*/}
                                            {/*{*/}
                                            {/*    item.desc.map((item, index) => {*/}
                                            {/*        return (*/}
                                            {/*            <div className="cost__block--container__description--name">*/}
                                            {/*                {item}*/}
                                            {/*            </div>*/}
                                            {/*        )*/}
                                            {/*    })*/}
                                            {/*}*/}
                                        {/*</div>*/}
                                        <div className="cost__block--container__info">
                                            {item.info}
                                        </div>
                                        <div className="cost__block--container__cost">
                                            {
                                                item.old &&
                                                    <div className="cost__block--container__cost--old">
                                                        {item.old}
                                                    </div>
                                            }
                                            <div className="cost__block--container__cost--price">
                                                {item.price}
                                            </div>
                                            <div className="cost__block--container__cost--day">
                                                {item.date}
                                            </div>
                                        </div>
                                        <div className="cost__block--container__button">
                                            <Link
                                                id={`h${index}`}
                                                to={item.href}
                                            >
                                                Подробнее
                                            </Link>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
