import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Header from "./containers/Header";
import Footer from "./containers/Footer";
import Main from "./containers/Main";
// import Preloader from "./components/Preloader";
import './styles.scss';
import Prices from "./containers/Prices";
import About from "./containers/About";
import Contacts from "./containers/Contacts";
import PrivacyPolicy from "./containers/PrivacyPolicy";
// import Benefits from "./components/Benefits";
import ContactUs from "./components/Conact-us";
import Disclamer from "./containers/Disclamer";
import LeftSide from "./containers/LeftSide";

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            headerOther: false,
        };
    }


    componentDidMount() {
        let str = 'Хочешь у нас работать, пиши на почту: feedback@landlix.ru';
        let style = [
            'width: 100%;',
            'text-align: center;',
            'padding: 10px;',
            'background: #f3f6f91f;',
            'border-radius: 6px;',
            'text-decoration: none;',
            'color: #ffffff;'
        ].join('');

        console.log ( '%c%s', style, str );
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route path="/disclamer">
                            <Header
                                headerOther={true}
                            />
                            <Disclamer/>
                        </Route>
                        <Route path="/privacy-policy">
                            <Header
                                headerOther={true}
                            />
                            <PrivacyPolicy/>
                        </Route>
                        <Route path="/contacts">
                            <Header
                                headerOther={true}
                            />
                            <Contacts/>
                            {/*<ContactUs*/}
                            {/*    title={true}*/}
                            {/*    titleText={'Обратная связь'}*/}
                            {/*    titleTag={'Контакты'}*/}
                            {/*/>*/}
                        </Route>
                        <Route path="/about-us">
                            <Header
                                headerOther={true}
                            />
                            <About titleTag={'О нас'}/>
                        </Route>
                        <Route path="/prices">
                            <Header
                                headerOther={true}
                            />
                            <LeftSide/>
                            <Prices
                                titleTag={'Цены'}
                            />
                        </Route>
                        <Route exact path="/">
                            <Header headerOther={false}/>
                            <LeftSide/>
                            <Main titleTag={'Заказать сайт в Веб студии Landlix'} />
                        </Route>
                    </Switch>
                    <Footer/>
                </Router>
            </React.Fragment>
        )
    }
}
