import React from 'react'
import './styles/SendEmail.scss'
// import pops from '../../components/SendEmailPHP/cont.php'

export default class SendEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: false,
            isLoaded: false,
            items: [],
            name: '',
            tel: '',
            email: '',
            message: '',
            checkCase: '',
            loader: false,
            success: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.name === 'select' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {
        const target = event.target;
        const value = target.id;

        this.setState({
            checkCase: value,
        },() => console.log('checkCase', this.state.checkCase, 'value', value));
        if (target.name === 'name') {
            this.setState({
                name: target.value,
            })
        }
        if (target.name === 'email') {
            this.setState({
                email: target.value,
            })
        }
        if (target.name === 'message') {
            this.setState({
                message: target.value
            })
        }
    }

    handleSubmit(event){
        event.preventDefault();
        let th = this;
        this.setState({
            loader: true,
        });
        fetch('https://landlix.ru/email/sendCase.php', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type':'application/x-www-form-urlencoded',
                'Accept':'application/x-www-form-urlencoded',
            },
            body: `name=${th.state.name}&email=${th.state.email}&message=${th.state.message}&select=${th.state.checkCase}`
        })
            .then(function(response) {
                let info = Promise.resolve(response);
                info.then((data) => {
                    console.log('data', data)
                    if (data.status === 200) {
                        th.setState({
                            error: false,
                        });
                        th.loaderSendNew()
                    } else {
                        th.setState({
                            success: false
                        });
                        th.errorSendNew()
                    }
                })
            })
    };

    loaderSendNew() {
        setTimeout(() => {
            this.setState({
                loader: false,
                success: true,
                name: '',
                tel: '',
                email: '',
                message: '',
                checkCase: ''
            })
        }, 2000);
        setTimeout(() => {
            this.setState({
                success: false
            })
        }, 6000)
    }

    errorSendNew() {
        setTimeout(() => {
            this.setState({
                loader: false,
                error: true,
            })
        }, 2000);
        setTimeout(() => {
            this.setState({
                error: false
            })
        }, 4000)
    }

    setType = (value) => {
        this.setState({
            checkCase: value
        })
    };

    open() {
        this.setState({
            open: true
        })
    };

    close() {
        this.setState({
            open: false
        })
    };

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        let { sections } = this.props;

        return (
            <React.Fragment>
                <div className={`send-email ${this.state.open ? 'send-email--on' : ''}`}>
                    <div className="send-email__container">
                        <div
                            className="send-email__container--close"
                            onClick={() => {
                                this.close()
                            }}
                        >
                            X
                        </div>
                        <div className="send-email__container--title">
                            Заполните форму ниже, после чего с вами свяжется наш менеджер.
                        </div>
                        <form
                            className="send-email__container--form"
                            onSubmit={this.handleSubmit}
                        >
                            <div className="send-email__container--form--checkbox">
                                <input
                                    name="select"
                                    type="radio"
                                    placeholder="asd"
                                    id="cardVisit"
                                    value="card-visit"
                                    checked={this.state.checkCase === 'cardVisit'}
                                    onChange={this.handleCheckChange}
                                    required="required"
                                />
                                <label htmlFor="cardVisit">
                                    Сайт-визитка
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="landingPage" value="landing-page"
                                       required="required"
                                       checked={this.state.checkCase === 'landingPage'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="landingPage">
                                    Лендинг
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="businessPage" value="business-page"
                                       required="required"
                                       checked={this.state.checkCase === 'businessPage'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="businessPage">
                                    Бизнес-сайт
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="storePage" value="store-page"
                                       required="required"
                                       checked={this.state.checkCase === 'storePage'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="storePage">
                                    Интернет-магазин
                                </label>
                            </div>
                            <div className="send-email__container--form--checkbox">
                                <input
                                    name="select"
                                    type="radio"
                                    placeholder="asd"
                                    id="design"
                                    value="design"
                                    checked={this.state.checkCase === 'design'}
                                    onChange={this.handleCheckChange}
                                    required="required"
                                />
                                <label htmlFor="design">
                                    Дизайн сайта
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="logo" value="logo"
                                       required="required"
                                       checked={this.state.checkCase === 'logo'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="logo">
                                    Логотип
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="yd" value="yd"
                                       required="required"
                                       checked={this.state.checkCase === 'yd'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="yd">
                                    Яндекс.Директ
                                </label>
                                <input name="select" type="radio" placeholder="asd" id="context" value="context"
                                       required="required"
                                       checked={this.state.checkCase === 'context'}
                                       onChange={this.handleCheckChange}
                                />
                                <label htmlFor="context">
                                    Контекстная реклама
                                </label>
                            </div>
                            <div className="send-email__container--form--input">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Имя"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}
                                    pattern="[A-Za-zА-Яа-я]{1,32}"
                                    required="required"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="E-mail"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    required="required"
                                />
                            </div>
                            <div className="send-email__container--form--text">
                                <textarea
                                    name="message"
                                    cols="40"
                                    rows="5"
                                    value={this.state.message}
                                    onChange={this.handleInputChange}
                                    required="required"
                                    placeholder="Текст"
                                />
                            </div>
                            <div className="send-email__container--form--button--block">
                                <button
                                    className={`send-email__container--form--button ${this.state.error ? 'send-email__container--form--button--error' : this.state.success ? 'send-email__container--form--button--success' : ''}`}
                                    type="submit"
                                    disabled={this.state.loader}
                                >
                                    <div className={`send-email__container--form--button--loader ${this.state.loader ? 'send-email__container--form--button--loader--yes' : ''}`}>
                                    </div>
                                    {
                                        this.state.success ?
                                            <div className="send-email__container--form--button--text">
                                                Отправлено
                                            </div>
                                            :
                                            this.state.error ?
                                                <div className="send-email__container--form--button--text">
                                                    Ошибка
                                                </div>
                                                :
                                                <div className={`send-email__container--form--button--text ${this.state.loader ? 'send-email__container--form--button--text--no' : ''}`}>
                                                    Отправить
                                                </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
