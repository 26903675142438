import React from 'react'
import './styles/Contact-us.scss'
import img1 from '../../assets/images/consulting_monochromatic.svg'

export default class ContactUs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            text: '',
            name: '',
            tel: '',
            email: '',
            message: '',
            loader: false,
            success: false
        };
        this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
        this.handleInputChangeEmail = this.handleInputChangeEmail.bind(this);
    }


    handleInputChangeEmail(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmitEmail(event){
        event.preventDefault();
        let th = this;
        this.setState({
            loader: true,
        });
        fetch('https://landlix.ru/email/cont.php', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type':'application/x-www-form-urlencoded',
                'Accept':'application/x-www-form-urlencoded'
            },
            body: `name=${th.state.name}&email=${th.state.email}&message=${th.state.message}`
        })
            .then(function(response) {
                let info = Promise.resolve(response);
                info.then((data) => {
                    if (data.status === 200) {
                        th.setState({
                            error: false,
                        });
                        th.loaderSend()
                    } else {
                        th.setState({
                            success: false
                        });
                        th.errorSend()
                    }
                })
            })
    };

    loaderSend() {
        setTimeout(() => {
            this.setState({
                loader: false,
                success: true,
                name: '',
                tel: '',
                email: '',
                message: '',
            })
        }, 2000);
        setTimeout(() => {
            this.setState({
                success: false
            })
        }, 6000)
    }

    errorSend() {
        setTimeout(() => {
            this.setState({
                loader: false,
                error: true,
            })
        }, 2000);
        setTimeout(() => {
            this.setState({
                error: false
            })
        }, 4000)
    }

    componentDidMount() {
        document.title = this.props.titleTag && this.props.titleTag
    }

    componentWillUnmount() {
    }

    render() {

        let {
            title,
            titleText,
            styles
        } = this.props;

        return (
            <React.Fragment>
                <div className="contact-us" id="contact-us">
                    {/*<img src={imgBack}/>*/}
                    <div className="contact-us__container--full">
                        <div
                            className={`contact-us--title ${title ? '' : 'contact-us--title--off'}`}
                        >
                            <p>
                                {
                                    titleText
                                }
                            </p>
                            <p>
                                Мы рады ответить Вам
                            </p>
                        </div>
                        <div className="contact-us__container">
                            <form
                                className="contact-us__form"
                                onSubmit={this.handleSubmitEmail}
                            >
                                <input
                                    className="contact-us__form--input"
                                    placeholder="Имя"
                                    type="name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleInputChangeEmail}
                                    pattern="[A-Za-zА-Яа-я]{1,32}"
                                    required
                                />
                                {/*<input*/}
                                    {/*className="contact-us__form--input"*/}
                                    {/*placeholder="+7**********"*/}
                                    {/*type="tel"*/}
                                    {/*name="tel"*/}
                                    {/*value={this.state.tel}*/}
                                    {/*onChange={this.handleInputChangeEmail}*/}
                                    {/*pattern="\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"*/}
                                {/*/>*/}
                                <input
                                    className="contact-us__form--input"
                                    placeholder="e-mail"
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChangeEmail}
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    required
                                />
                                <textarea
                                    className="contact-us__form--input"
                                    placeholder="Текст"
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.handleInputChangeEmail}
                                    rows="4"
                                    required
                                />
                                <div className="contact-us__form--input--checkbox">
                                    <input type="checkbox" placeholder="asd" id="checkbox" required/>
                                    <label htmlFor="checkbox">
                                        Нажимая на кнопку, Вы соглашаетесь с политикой конфиденциальности.
                                    </label>
                                </div>
                                <button
                                    className={`contact-us__form--input--button ${this.state.error ? 'contact-us__form--input--button--error' : this.state.success ? 'contact-us__form--input--button--success' : ''}`}
                                    type="submit"
                                    disabled={this.state.loader}
                                >
                                    <div className={`contact-us__form--input--button--loader ${this.state.loader ? 'contact-us__form--input--button--loader--yes' : ''}`}>
                                    </div>
                                    {
                                        this.state.success ?
                                            <div className="contact-us__form--input--button--text">
                                                Отправлено
                                            </div>
                                            :
                                            this.state.error ?
                                            <div className="contact-us__form--input--button--text">
                                                Ошибка
                                            </div>
                                                :
                                            <div className={`contact-us__form--input--button--text ${this.state.loader ? 'contact-us__form--input--button--text--no' : ''}`}>
                                                Отправить
                                            </div>
                                    }
                                </button>
                            </form>
                            {/*<img src={img1}/>*/}
                        </div>
                    </div>
                    <div className="contact-us__container--img">

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
