import React from 'react'

import classNames from 'classnames';
import SendEmail from "../../components/SendEmail";

function animate(options) {

    let start = performance.now();

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / options.duration;
        if (timeFraction > 1) timeFraction = 1;

        let progress = options.timing(timeFraction);
        options.draw(progress);

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        }

    });
}

// function bounce(timeFraction) {
//     for (let a = 0, b = 1, result; 1; a += b, b /= 2) {
//         if (timeFraction >= (7 - 4 * a) / 11) {
//             return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2)
//         }
//     }
// }

const typingList = [
    {
        text: `Сайта-визитки`,
    },
    {
        text: `Landing page`,
    },
    {
        text: `Бизнес-сайта`,
    },
    {
        text: `Online-магазина`,
    },
];


class Typing extends React.PureComponent {
    _mounted = false;

    state = {
        index: 1
    };

    animateText() {
        let listItem = typingList[this.state.index];
        let text = listItem.text;

        let to = text.length,
            from = 0;

        this.cursor.classList.add('text-typing--cursor--stop');

        animate({
            duration: 1800,
            timing: function (timeFraction) {
                return timeFraction;
            },
            draw: (progress) => {
                if (this._mounted) {
                    let result = (to - from) * progress + from;
                    this.text.innerText = text.substr(0, Math.ceil(result));
                    if (progress === 1) {
                        this.cursor.classList.remove('text-typing--cursor--stop');
                        this.timer = setTimeout(() => {
                            this.hideText();
                        }, 2e3)
                    }
                }
            }
        });
    };

    hideText() {
        let {index} = this.state;
        let listItem = typingList[index];
        let text = listItem.text;

        let to = 0,
            from = text.length;
        this.cursor.classList.add('text-typing--cursor--stop');

        animate({
            duration: 800,
            timing: function (timeFraction) {
                return timeFraction;
            },
            draw: (progress) => {
                if (this._mounted) {
                    let result = (to - from) * progress + from;
                    this.text.innerText = text.substr(0, Math.ceil(result));
                    if (progress === 1) {
                        this.cursor.classList.remove('text-typing--cursor--stop');
                        // this.author.innerText = '';
                        this.timer = setTimeout(() => {
                            this.setState({index: index === typingList.length - 1 ? 0 : index + 1}, () => {
                                this.animateText();
                            })
                        }, 2e3)
                    }
                }
            }
        });
    }

    componentDidMount() {
        this._mounted = true;
        this.animateText();
    }

    componentWillUnmount() {
        this._mounted = false;
        if (this.timer)
            clearTimeout(this.timer);
    }

    renderText(data){
        return (
            <React.Fragment>
                <span ref={e => this.text = e}>

                </span>
                <div className="text-typing--cursor--change">
                    <div
                        ref={e => this.cursor = e}
                        className={classNames({
                            'text-typing--cursor': true,
                            'text-typing--cursor--stop': false,
                        })}
                    />
                    <div
                        className='text-typing--author'
                        ref={e => this.author = e}
                    >
                        {data.author}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        let data = typingList[this.state.index];

        return <div className="text-typing">
            {data.position === 'before' && <React.Fragment>
                {this.renderText(data)}
                <br/>
            </React.Fragment>}
            <b>
            <span>
            </span>
            </b>
            {data.position !== 'before' && this.renderText(data)}
        </div>
    }
}

export default class Head extends React.Component{
    constructor(props) {
        super(props);
    }

    listenScrollEvent = e => {
        let head = document.querySelector('.head');
        if (!head) return;
        const cliHgt = head.scrollTop;
        const cliHgtTwo = head.clientHeight + (head.clientHeight / 4);
        const scroll = window.scrollY;
        let parallax1 = (((scroll - (cliHgt / 2.5)) / 6) * -1);
        let parallax2 = (((scroll - (cliHgt / 2.5)) / 2) * -1);
        let parallax3 = (((scroll - (cliHgt / 2.5)) / 1.5) * -1);
        let parallax4 = (((scroll - (cliHgt / 2.5)) / 4) * -1);
        if (scroll <= cliHgt) {
            if (head) {
                document.querySelector('.parallax-one').style.transform = `translateY(${parallax1}px)`;
                document.querySelector('.parallax-two').style.transform = `translateY(${parallax2}px)`;
                document.querySelector('.parallax-three').style.transform = `translateY(${parallax3}px)`;
                document.querySelector('.parallax-four').style.transform = `translateY(${parallax4}px)`;
            }
        }
        if (scroll <= cliHgtTwo) {
            document.querySelector('.parallax-one').style.transform = `translateY(${parallax1}px)`;
            document.querySelector('.parallax-two').style.transform = `translateY(${parallax2}px)`;
            document.querySelector('.parallax-three').style.transform = `translateY(${parallax3}px)`;
            document.querySelector('.parallax-four').style.transform = `translateY(${parallax4}px)`;
        }
    };

    componentDidMount() {
        if (window.location.pathname === '/') {
            window.addEventListener('scroll', this.listenScrollEvent);
        }
    }

    componentWillUnmount() {
        if (window.location.pathname === '/') {
            window.removeEventListener('scroll', this.listenScrollEvent);
        }
    }

    render() {
        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSend = node)}
                />
                <div className="head" id="head">
                    <div className="head__back-stars parallax-one"/>
                    <div className="head__back-vall"/>
                    {/*<svg className="editorial"*/}
                         {/*xmlns="http://www.w3.org/2000/svg"*/}
                         {/*xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                         {/*// viewBox="0 24 150 28"*/}
                         {/*preserveAspectRatio="none">*/}
                        {/*<defs>*/}
                            {/*<path id="cloud-1"*/}
                                  {/*d="M370,123a65.31,65.31,0,0,0-10,.8A61.57,61.57,0,0,0,298.5,63c-1.5,0-3,.1-4.5.2a93.53,93.53,0,0,0-175.2-4.7A91.49,91.49,0,0,0,93.5,55,93.55,93.55,0,0,0,0,148.5C0,200.1,40.4,248,92,248H370c35.3,0,64-25.7,64-61A64.06,64.06,0,0,0,370,123Z"/>*/}
                            {/*<path id="cloud-2"*/}
                                  {/*d="M513.9,168.1A89,89,0,0,0,426,93h-.1a48.52,48.52,0,0,0-48.4-46,45.69,45.69,0,0,0-12.9,1.8A135.5,135.5,0,0,0,138.1,77.3,100.5,100.5,0,1,0,100.5,271h411a51.48,51.48,0,0,0,2.4-102.9Z"/>*/}
                        {/*</defs>*/}
                        {/*<g className="parallax">*/}
                            {/*<use xlinkHref="#cloud-1" x="50" y="0" fill="#80aaff40"/>*/}
                            {/*<use xlinkHref="#cloud-2" x="50" y="3" fill="#80aaff40"/>*/}
                            {/*<use xlinkHref="#cloud-1" x="50" y="6" fill="#ffffff54"/>*/}
                            {/*<use xlinkHref="#cloud-2" x="50" y="9" fill="#80aaff40"/>*/}
                            {/*<use xlinkHref="#cloud-1" x="50" y="12" fill="#80aaff40"/>*/}
                            {/*<use xlinkHref="#cloud-2" x="50" y="15" fill="#ffffff54"/>*/}
                            {/*<use xlinkHref="#cloud-1" x="50" y="18" fill="#80aaff40"/>*/}
                            {/*<use xlinkHref="#cloud-2" x="50" y="21" fill="#fff"/>*/}
                            {/*<use xlinkHref="#cloud-2" x="50" y="22" fill="#fff"/>*/}
                        {/*</g>*/}
                    {/*</svg>*/}
                    <div className="head__block">
                        <div className="head__block--title parallax-three">
                            Студия по созданию
                        </div>
                        <div className="head__block--title--big parallax-two">
                            <Typing/>
                        </div>
                        <div className="head__block--title parallax-four">
                            Для вашего бизнеса
                        </div>
                        <div
                            className="head__block--button"
                            onClick={() => {
                                this.modalSend.open()
                            }}
                        >
                            Заказать
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
