import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { createStore } from 'redux';
//
// function playlist(state = [], action) {
//     console.log(action);
//     return state;
// }
//
// const store = createStore(playlist);
//
// store.subscribe(() => {
//     console.log(store.getState());
// })
// store.dispatch({ type: 'ADD_TRACK', payload: 'Smells like spirit' });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
