import React from 'react'

import './styles/Prices.scss'

import backOne from '../../assets/images/top-back-vall.svg'
import imSix from "../../assets/images/back-line-two.svg";
import imSeven from "../../assets/images/back-line-three.svg";
import imgOne from "../../assets/images/block-img-one.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";
import ContactUs from "../../components/Conact-us";
import SendEmail from "../SendEmail";
import {Link, NavLink} from "react-router-dom";

export default class Services extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            prices: [
                {
                    id: 'one',
                    name: 'Полный цикл услуг',
                    cost: 'От разработки фирменного стиля до продвижения сайта.',
                    img: imgOne
                },
                {
                    id: 'two',
                    name: 'Гибкость',
                    cost: 'Работаем с клиентом в одной команде.',
                    img: imgTwo
                },
                {
                    id: 'three',
                    name: 'Мобильная версия',
                    cost: 'При разработке получаете мобильную версию сайта.',
                    img: imgThree
                },
                {
                    id: 'four',
                    name: 'Хостинг',
                    cost: 'Бесплатный хостинг на высокоскоростных и надежных серверах.',
                    img: imgFour
                },
                {
                    id: 'five',
                    name: 'Техническая поддержка',
                    cost: 'Бесплатная поддержка до 3 месяцев.',
                    img: imgFive
                },
                {
                    id: 'six',
                    name: 'Функциональная  CMS',
                    cost: 'Понятная и простая панель управления.',
                    img: imgSix
                },
            ]
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.document.title = `Заказать ${this.props.title && this.props.title}`
        }, 0)
    }

    componentWillUnmount() {
    }

    render() {

        let { sections, menu } = this.props;
        document.title = this.props.title && this.props.title
        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSending = node)}
                />
                {
                    menu &&
                    <div
                        className="prices__block prices__block--center"
                        style={{
                            marginTop: '1em'
                        }}
                    >
                        <div className="prices__block--container">
                            {/*<div className="prices__menu--title">*/}
                            {/*    <div className="prices__menu--title--one">*/}
                            {/*        Разработка:*/}
                            {/*    </div>*/}
                            {/*    /!*<Link*!/*/}
                            {/*    /!*    to="/"*!/*/}
                            {/*    /!*    className="prices__menu--title--two"*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*    Я не знаю какой вариант мне подходит.*!/*/}
                            {/*    /!*</Link>*!/*/}
                            {/*</div>*/}
                            <div className="prices__menu">
                                <NavLink
                                    to='/prices/dev/card'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Сайт-визитка
                                </NavLink>
                                <NavLink
                                    to='/prices/dev/landing-page'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Лендинг
                                </NavLink>
                                <NavLink
                                    to='/prices/dev/business-site'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Бизнес-сайт
                                </NavLink>
                                <NavLink
                                    to='/prices/dev/online-store'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Интернет-магазин
                                </NavLink>
                                <NavLink
                                    to='/prices/dev/more'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Доп. Услуги
                                </NavLink>
                            </div>
                        </div>
                    </div>
                }
                <div className="price-all">
                    <div className="price-all__block">
                        <div className="price-all__block__info">
                            <div className="price-all__block__info--name">
                                {
                                    sections.name
                                }
                                {
                                    sections.sale &&
                                    <div className="price-all__block__info--name--sale">
                                        {
                                            '- ' + sections.sale
                                        }
                                    </div>
                                }
                            </div>
                            <div className="price-all__block__info--desc">
                                {
                                    sections.text
                                }
                            </div>
                            <div className="price-all__block__info--cost">
                                Стоимость:
                                {
                                    sections.oldPrice &&
                                    <div className="price-all__block__info--cost--val--old">
                                        {
                                            sections.oldPrice
                                        }
                                    </div>
                                }
                                <div className="price-all__block__info--cost--val">
                                    {
                                        sections.price
                                    }
                                </div>
                            </div>
                            <div className="price-all__block__info--time">
                                Срок разработки:
                                <div className="price-all__block__info--time--val">
                                    {
                                        sections.date
                                    }
                                </div>
                            </div>
                            {
                                sections.typeSend &&
                                <button
                                    className="price-all__block__info--button"
                                    onClick={() => {
                                        this.modalSending.open();
                                        this.modalSending.setType(sections.typeSend);
                                    }}
                                >
                                    Заказать
                                </button>
                            }
                            <Link
                                className="head-new__container--box--five"
                                to="/contacts"
                            >
                                Написать нам
                            </Link>
                        </div>
                        <div className="price-all__block__desc">
                            <div className="price-all__block__desc--title">
                                Кому подходит данный вариант
                            </div>
                            <div className="price-all__block__desc--desc">
                                {
                                    sections.desc
                                }
                            </div>
                        </div>
                    </div>
                    {
                        sections.dev &&
                        <div className="price-all__block">
                            <div className="price-all__block__included">
                                <div className="price-all__block__included--title">
                                    В стоимость входит
                                </div>
                                <div className="price-all__block__included--info">
                                    <div className="price-all__block__included--info--box">
                                        <div className="price-all__block__included--info--box--name">
                                            Разработка сайта
                                        </div>
                                        {
                                            sections.dev[0].one.map((item, index) => {
                                                return (
                                                    <div key={index} className="price-all__block__included--info--box--item">
                                                        {
                                                            item
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="price-all__block__included--info--box">
                                        <div className="price-all__block__included--info--box--name">
                                            Обслуживание сайта
                                        </div>
                                        {
                                            sections.dev[0].two.map((item, index) => {
                                                return (
                                                    <div key={index} className="price-all__block__included--info--box--item">
                                                        {
                                                            item
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="price-all__block__included--info--box">
                                        <div className="price-all__block__included--info--box--name">
                                            Продвижение сайта
                                        </div>
                                        {
                                            sections.dev[0].three.map((item, index) => {
                                                return (
                                                    <div key={index} className="price-all__block__included--info--box--item">
                                                        {
                                                            item
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/*{*/}
                    {/*    this.props.more && this.props.more &&*/}
                    {/*    <div className="price-all__block" style={{width: '90%'}}>*/}
                    {/*        <div className="price-all__block__included">*/}
                    {/*            <div className="price-all__block__included--title">*/}
                    {/*                Пример*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*<ContactUs*/}
                    {/*    title={true}*/}
                    {/*    titleText={'Если нужно больше – напишите нам'}*/}
                    {/*/>*/}
                </div>
            </React.Fragment>
        )
    }
}
