import React from 'react'
import './styles/Benefits.scss'
import { Link } from "react-router-dom";
import imSeven from "../../assets/images/back-line-three.svg";

export default class AboutUs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let {
            data,
            left
        } = this.props;
        return (
            <React.Fragment>
                <div className={`benefits ${this.props.left ? 'benefits--left' : ''}`} id="benefits">
                    {/*<div className="about-us__back--one"/>*/}
                    {/*{*/}
                        {/*this.props.background &&*/}
                        {/*<div className="benefits__back">*/}
                                {/*<img*/}
                                    {/*className={this.props.left ? 'benefits__back--img--right' : 'benefits__back--img--left'}*/}
                                    {/*src={imSeven}*/}
                                {/*/>*/}
                        {/*</div>*/}
                    {/*}*/}
                    <div className={`benefits__block ${this.props.left ? 'benefits__block--left' : ''}`}>
                        <div className={`benefits__block--text ${this.props.left ? 'benefits__block--text--left' : ''} ${this.props.blockLeft ? 'benefits__block--text--block-left' : ''}`}>
                            <div className="benefits--title">
                                <p>
                                    {
                                        data.preTitle
                                    }
                                </p>
                                <p>
                                    {
                                        data.title
                                    }
                                </p>
                            </div>
                            {
                                data.desc &&
                                    <div className="benefits--contact">
                                        {data.desc}
                                    </div>
                            }
                            {
                                data.link &&
                                <Link
                                    class="benefits__block--text--link"
                                    to={data.link}
                                >
                                    Подробнее
                                </Link>
                            }
                        </div>
                        {/*<div className={`benefits__block--img ${this.props.left ? 'benefits__block--img--left' : ''}`}>*/}
                        {/*    <img src={data.img}/>*/}
                        {/*</div>*/}
                        <div className={`benefits__block--img ${this.props.left ? 'benefits__block--img--left' : ''}`}>
                            {/*<img src={data.img}/>*/}
                            {
                                this.props.left ?
                                    <div className="benefits__block--full--two"/>
                                    :
                                    <div className="benefits__block--full"/>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
