import React from 'react'
import './styles/Promo2.scss'
import imOne from '../../assets/images/balance-scale.svg'
import imTwo from '../../assets/images/computer.svg'
import imThree from '../../assets/images/seo-and-web.svg'
import imFour from '../../assets/images/crp.svg'
import imFive from '../../assets/images/crb.svg'
import imSix from '../../assets/images/shield.svg'
// import imOne from '../../assets/images/progress__monochromatic.svg'
// import imOne from '../../assets/images/analytics_process_monochromatic.svg'
// import imOneAnim from '../../assets/images/one-block--anim.svg'

export default class promo22 extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="promo2" id="promo22">
                    <div className="promo2__container">
                        <h1 className="promo2__container--title">
                            Наши,
                            <div className="promo2__container--title--accent">
                                преимущества.
                            </div>
                        </h1>
                        <div className="promo2__container--block">
                            <div>
                                <div className="promo2__container--block--box">
                                    <img src={imOne}/>
                                    <p>Конверсия</p>
                                    <p>Правильно разработаные нами сайты, имеют хорошую конверсию, благодаря чему, вы получаете клиентов и экономите на рекламе.</p>
                                </div>
                                <div className="promo2__container--block--box">
                                    <img src={imTwo}/>
                                    <p>Скорость</p>
                                    <p>Клиенты не любят долго ждать загрузки страници – благадоря быстродействию разработаных нами сайтов пользователь не ждет долгой загрузки страницы.</p>
                                </div>
                                <div className="promo2__container--block--box">
                                    <img src={imThree}/>
                                    <p>SEO</p>
                                    <p>При разработке мы закладываем структуру SEO оптимизации – сайт на этапе запуска, уже оптимизирован.</p>
                                </div>
                            </div>
                            <div>
                                <div className="promo2__container--block--box">
                                    <img src={imFour}/>
                                    <p>Кроссплатформенность</p>
                                    <p>Мы реализовываем адаптивную структуру сайта под разные устройства.</p>
                                </div>
                                <div className="promo2__container--block--box">
                                    <img src={imFive}/>
                                    <p>Кроссбраузерность</p>
                                    <p>Мы реализовываем структуру сайта под разные браузеры.</p>
                                </div>
                                <div className="promo2__container--block--box">
                                    <img src={imSix}/>
                                    <p>Защита</p>
                                    <p>Реализация защищенности сайта от мошенников.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
