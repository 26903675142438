import React from 'react'

import ContactUs from "../../../../components/Conact-us";
import SendEmail from "../../../SendEmail";
import {Link, NavLink} from "react-router-dom";

export default class MoreMark extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.document.title = `Заказать ${this.props.title && this.props.title}`
        }, 0)
    }

    componentWillUnmount() {
    }

    render() {

        let {sections, menu} = this.props;
        document.title = this.props.title && this.props.title
        return (
            <React.Fragment>
                <SendEmail
                    ref={(node) => (this.modalSending = node)}
                />
                {
                    menu &&
                    <div
                        className="prices__block prices__block--center"
                        style={{
                            marginTop: '1em'
                        }}
                    >
                        <div className="prices__block--container">
                            {/*<div className="prices__menu--title">*/}
                            {/*    <div className="prices__menu--title--one">*/}
                            {/*        Разработка:*/}
                            {/*    </div>*/}
                            {/*    /!*<Link*!/*/}
                            {/*    /!*    to="/"*!/*/}
                            {/*    /!*    className="prices__menu--title--two"*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*    Я не знаю какой вариант мне подходит.*!/*/}
                            {/*    /!*</Link>*!/*/}
                            {/*</div>*/}
                            <div className="prices__menu">
                                <NavLink
                                    to='/prices/seo/yd'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Яндекс Директ
                                </NavLink>
                                <NavLink
                                    to='/prices/seo/context'
                                    className="prices__menu--link"
                                    activeClassName="prices__menu--link--active"
                                    // onClick={() => this.check()}
                                >
                                    Контекстная реклама
                                </NavLink>
                            </div>
                        </div>
                    </div>
                }
                <div className="price-all">
                    <div className="price-all__block">
                        <div className="price-all__block__info">
                            <div className="price-all__block__info--name">
                                {
                                    sections.name
                                }
                                {
                                    sections.sale &&
                                    <div className="price-all__block__info--name--sale">
                                        {
                                            '- ' + sections.sale
                                        }
                                    </div>
                                }
                            </div>
                            <div className="price-all__block__info--desc">
                                {
                                    sections.text
                                }
                            </div>
                            <div className="price-all__block__info--cost">
                                Стоимость:
                                {
                                    sections.oldPrice &&
                                    <div className="price-all__block__info--cost--val--old">
                                        {
                                            sections.oldPrice
                                        }
                                    </div>
                                }
                                <div className="price-all__block__info--cost--val">
                                    {
                                        sections.price
                                    }
                                </div>
                            </div>
                            <div className="price-all__block__info--time">
                                Срок разработки:
                                <div className="price-all__block__info--time--val">
                                    {
                                        sections.date
                                    }
                                </div>
                            </div>
                            {
                                sections.typeSend &&
                                <button
                                    className="price-all__block__info--button"
                                    onClick={() => {
                                        this.modalSending.open();
                                        this.modalSending.setType(sections.typeSend)
                                    }}
                                >
                                    Заказать
                                </button>
                            }
                            <Link
                                className="head-new__container--box--five"
                                to="/contacts"
                            >
                                Написать нам
                            </Link>
                        </div>
                        <div className="price-all__block__desc">
                            <div className="price-all__block__desc--title">
                                Кому подходит данный вариант
                            </div>
                            <div className="price-all__block__desc--desc">
                                {
                                    sections.desc
                                }
                            </div>
                        </div>
                    </div>
                    {
                        sections.dev &&
                        <div className="price-all__block">
                            <div className="price-all__block__included">
                                <div className="price-all__block__included--title">
                                    В стоимость входит
                                </div>
                                <div className="price-all__block__included--info">
                                    {
                                        sections.dev[0].one &&
                                        <div className="price-all__block__included--info--box">
                                            <div className="price-all__block__included--info--box--name">
                                                {
                                                    sections.one ? sections.one : 'Разработка'
                                                }
                                            </div>
                                            {
                                                sections.dev[0].one.map((item, index) => {
                                                    return (
                                                        <div key={index} className="price-all__block__included--info--box--item">
                                                            {
                                                                item
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        sections.dev[0].two &&
                                        <div className="price-all__block__included--info--box">
                                            <div className="price-all__block__included--info--box--name">
                                                {
                                                    sections.two ? sections.two : 'Обслуживание'
                                                }
                                            </div>
                                            {
                                                sections.dev[0].two.map((item, index) => {
                                                    return (
                                                        <div key={index} className="price-all__block__included--info--box--item">
                                                            {
                                                                item
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        sections.dev[0].three &&
                                        <div className="price-all__block__included--info--box">
                                            <div className="price-all__block__included--info--box--name">
                                                {
                                                    sections.three ? sections.three : 'Продвижение'
                                                }
                                            </div>
                                            {
                                                sections.dev[0].three.map((item, index) => {
                                                    return (
                                                        <div key={index} className="price-all__block__included--info--box--item">
                                                            {
                                                                item
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {/*<ContactUs*/}
                    {/*    title={true}*/}
                    {/*    titleText={'Если нужно больше – напишите нам'}*/}
                    {/*/>*/}
                </div>
            </React.Fragment>
        )
    }
}
