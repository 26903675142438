import React from 'react'

import './styles/Services.scss'

import backOne from '../../assets/images/top-back-vall.svg'
import imSix from "../../assets/images/back-line-two.svg";
import imSeven from "../../assets/images/back-line-three.svg";
import imgOne from "../../assets/images/block-img-one.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";

export default class Services extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            services: [
                {
                    id: 'one',
                    name: 'Полный цикл услуг',
                    cost: 'От разработки фирменного стиля до продвижения сайта.',
                    img: imgOne
                },
                {
                    id: 'two',
                    name: 'Гибкость',
                    cost: 'Работаем с клиентом в одной команде.',
                    img: imgTwo
                },
                {
                    id: 'three',
                    name: 'Мобильная версия',
                    cost: 'При разработке получаете мобильную версию сайта.',
                    img: imgThree
                },
                {
                    id: 'four',
                    name: 'Хостинг',
                    cost: 'Бесплатный хостинг на высокоскоростных и надежных серверах.',
                    img: imgFour
                },
                {
                    id: 'five',
                    name: 'Техническая поддержка',
                    cost: 'Бесплатная поддержка до 3 месяцев.',
                    img: imgFive
                },
                {
                    id: 'six',
                    name: 'Функциональная  CMS',
                    cost: 'Понятная и простая панель управления.',
                    img: imgSix
                },
            ]
        }
    }

    moveBox() {

        let { services } = this.state;

        services.forEach((item, index) => {
            let container = document.querySelector(`.services__block__box--${item.id}`),
                inner = document.querySelector(`.services__block__box--back--${item.id}`);

            // Mouse
            const mouse = {
                _x: 0,
                _y: 0,
                x: 0,
                y: 0,
                updatePosition: function (event) {
                    var e = event || window.event;
                    this.x = e.clientX - this._x;
                    this.y = (e.clientY - this._y) * -1;
                },
                setOrigin: function (e) {
                    this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
                    this._y = e.offsetHeight;
                },
                show: function () {
                    return "(" + this.x + ", " + this.y + ")";
                }
            };

            // Track the mouse position relative to the center of the container.
            mouse.setOrigin(container);

            //----------------------------------------------------

            let counter = 0;
            const refreshRate = 10;
            const isTimeToUpdate = function () {
                return counter++ % refreshRate === 0;
            };

            //----------------------------------------------------

            const onMouseEnterHandler = function (event) {
                update(event);
            };

            const onMouseLeaveHandler = function () {
                inner.style = "";
            };

            const onMouseMoveHandler = function (event) {
                if (isTimeToUpdate()) {
                    update(event);
                }
            };

            const updateTransformStyle = function(x, y) {
                const style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
                inner.style.transform = style;
                inner.style.webkitTransform = style;
                inner.style.mozTranform = style;
                inner.style.msTransform = style;
                inner.style.oTransform = style;
            };
            const update = function(event) {
                mouse.updatePosition(event);
                updateTransformStyle(
                    (mouse.y / inner.offsetHeight / 2).toFixed(2),
                    (mouse.x / inner.offsetWidth / 2).toFixed(2)
                );
            };

            container.onmousemove = onMouseMoveHandler;
            container.onmouseleave = onMouseLeaveHandler;
            container.onmouseenter = onMouseEnterHandler;
        });

    }


    componentDidMount() {
        // this.moveBox()
    }

    componentWillUnmount() {
    }

    render() {

        let { services } = this.state;

        return (
            <React.Fragment>
                <div className="services">
                    {/*<div className="services--img"/>*/}
                    <div className="services__back">
                        <img className="services__back--img--right" src={imSeven}/>
                    </div>
                    <div className="services__block">
                        {
                            services.map((item, index) => {
                                return (
                                    <div key={index} className={`services__block__box services__block__box--${item.id}`}>
                                        <div className={`services__block__box--back services__block__box--back--${item.id}`}>
                                            <img className="services__block__box--img" src={item.img}/>
                                            <div className="services__block__box--tab">
                                                <div className="services__block__box--title">
                                                    {item.name}
                                                </div>
                                                <div className="services__block__box--desc">
                                                    {item.cost}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
