import React from 'react'
import './styles/ListSiteNew.scss'

import b1 from '../../assets/images/tamplates/business/view.landlix.ru_business_1_.png'
import b2 from '../../assets/images/tamplates/business/view.landlix.ru_business_2_.png'
import b3 from '../../assets/images/tamplates/business/view.landlix.ru_business_3_.png'
import b4 from '../../assets/images/tamplates/business/view.landlix.ru_business_4_.png'

import c1 from '../../assets/images/tamplates/card/view.landlix.ru_card_1_.png'
import c2 from '../../assets/images/tamplates/card/view.landlix.ru_card_2.png'
import c3 from '../../assets/images/tamplates/card/view.landlix.ru_card_3_.png'
import c4 from '../../assets/images/tamplates/card/view.landlix.ru_card_4_.png'

import l1 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_1_.png'
import l2 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_2_.png'
import l3 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_3_.png'
import l4 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_4_.png'
import l5 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_5_.png'
import l6 from '../../assets/images/tamplates/landing/view.landlix.ru_landing_6_.png'

import o1 from '../../assets/images/tamplates/online-store/view.landlix.ru_online-store_1_.png'

export default class ListExample extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            slides: 0,
            // arrBox: [
            //     <figure ref={(node) => (this.box = node)} className="list-site-new--container--block--box">
            //     </figure>,
            //     <figure className="list-site-new--container--block--box">
            //     </figure>,
            //     <figure className="list-site-new--container--block--box">
            //     </figure>,
            //     <figure className="list-site-new--container--block--box">
            //     </figure>,
            //     <figure className="list-site-new--container--block--box">
            //     </figure>
            // ],
            comp: false,
            currTransl: [],
            num: 0,
            // list: [
            //     {
            //         img: imOne
            //     },
            // ],
        }
    }

    render() {
        let alls = [
            [
                {
                    img: b1,
                    href: 'https://view.landlix.ru/business/1/'
                },
                {
                    img: b2,
                    href: 'https://view.landlix.ru/business/2/'
                },
                {
                    img: b3,
                    href: 'https://view.landlix.ru/business/3/'
                },
                {
                    img: b4,
                    href: 'https://view.landlix.ru/business/4/'
                },
            ],
            [
                {
                    img: c1,
                    href: 'https://view.landlix.ru/card/1/'
                },
                {
                    img: c2,
                    href: 'https://view.landlix.ru/card/2/'
                },
                {
                    img: c3,
                    href: 'https://view.landlix.ru/card/3/'
                },
                {
                    img: c4,
                    href: 'https://view.landlix.ru/card/4/'
                },
            ],
            [
                {
                    img: l1,
                    href: 'https://view.landlix.ru/landing/1/'
                },
                {
                    img: l2,
                    href: 'https://view.landlix.ru/landing/2/'
                },
                {
                    img: l3,
                    href: 'https://view.landlix.ru/landing/3/'
                },
                {
                    img: l4,
                    href: 'https://view.landlix.ru/landing/4/'
                },
                {
                    img: l5,
                    href: 'https://view.landlix.ru/landing/5/'
                },
                {
                    img: l6,
                    href: 'https://view.landlix.ru/landing/6/'
                },
            ],
            [
                {
                    img: o1,
                    href: 'https://view.landlix.ru/online-store/1/'
                },
            ],
        ]

        console.log('asdasd', alls)

        return (
            <React.Fragment>
                <div className="list-site-new" id="list-site-new">
                    <div className="list-site-new--container">
                        <div className="list-site-new--container--slide">
                            <div className="list-site-new--container--slide--move">
                                {
                                    this.props.item && alls[this.props.item].map((item, index) => {
                                        return (
                                            <a
                                                key={index}
                                                href={item.href}
                                                className="list-site-new--container--slide--move--box"
                                                target="_blank"
                                            >
                                                <img src={item.img} alt=""/>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/*<div className="list-site-new--container--slide">*/}
                        {/*    <div className="list-site-new--container--slide--move--two">*/}
                        {/*        {*/}
                        {/*            this.state.list.map((item, index) => {*/}
                        {/*                return (*/}
                        {/*                    <div*/}
                        {/*                        key={index}*/}
                        {/*                        className="list-site-new--container--slide--move--two--box"*/}
                        {/*                    >*/}
                        {/*                        <img src={item.img} alt=""/>*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="list-site-new--container--slide">*/}
                        {/*    <div className="list-site-new--container--slide--move">*/}
                        {/*        {*/}
                        {/*            this.state.listNew.map((item, index) => {*/}
                        {/*                return (*/}
                        {/*                    <div*/}
                        {/*                        key={index}*/}
                        {/*                        className="list-site-new--container--slide--move--box"*/}
                        {/*                    >*/}
                        {/*                        <img src={item.img} alt=""/>*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
