import React from 'react'
import './styles/How-work.scss'
import imOne from '../../assets/images/pen.svg'
import imTwo from '../../assets/images/computer-design.svg'
import imThree from '../../assets/images/editing.svg'
import imFour from '../../assets/images/magic.svg'
import imFive from '../../assets/images/format.svg'
import imSix from '../../assets/images/back-line-two.svg'
import imSeven from '../../assets/images/back-line-three.svg'
import spaceOne from '../../assets/images/space-one.svg'
import spaceTwo from '../../assets/images/space-two.svg'
import spaceThree from '../../assets/images/space-three.svg'

export default class HowWork extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    id: 0,
                    img: imOne,
                    title: 'Прототип',
                    desc: 'Разработка макета сайта с текстовым наполнением.'
                },
                {
                    id: 1,
                    img: imTwo,
                    title: 'Дизайн',
                    desc: 'Создание фирменного стиля и наполнение макета сайта инфографикой и изображениями.'
                },
                {
                    id: 2,
                    img: imThree,
                    title: 'Верстка сайта',
                    desc: 'Разработка адаптивной версии сайта.'
                },
                {
                    id: 3,
                    img: imFour,
                    title: 'Установка скриптов',
                    desc: 'Отправки заявок на email или в CRM систему и подключение дополнительных модулей.'
                },
                {
                    id: 4,
                    img: imFive,
                    title: 'Аналитика',
                    desc: 'Подключение систем Yandex Metrikа и Google analytics, установка конверсионных целей для подсчета стоимости лида.'
                },
            ],
            stepsActive: [0]
        };
        this.setActives = this.setActives.bind(this);
    }

    scrollChange() {
        if (window.location.pathname === '/') {
            let blockHeight = document.getElementById('how-work').clientHeight;
            let scrollTopBlock = document.getElementById('how-work').getBoundingClientRect();
            let scrollTopBlockY = scrollTopBlock['y'];
            let animations = document.querySelector('.how-work__back');
            if (window.scrollY <= (scrollTopBlockY * -1) && window.scrollY >= (scrollTopBlockY * -1)) {
                animations.style.visibility = "hidden"
            }

            // console.log('scrollTopBlockY', scrollTopBlockY, 'blockHeight', blockHeight);
            let index1 = 0;
            let index2 = 0;
            let index3 = 0;
            if (scrollTopBlockY <= 0 && (scrollTopBlockY * -1) <= blockHeight) {
                // let path = document.querySelector('#star-path');
                //
                // let pathLength = path.getTotalLength();
                //
                // path.style.strokeDasharray = pathLength + ' ' + pathLength;
                //
                // path.style.strokeDashoffset = pathLength;
                //
                // let scrollPercentage = (scrollTopBlockY + document.body.scrollTop) / (blockHeight - document.documentElement.clientHeight);
                //
                // let drawLength = pathLength * scrollPercentage;
                //
                // path.style.strokeDashoffset = pathLength - drawLength;
                //
                // if (scrollPercentage >= 0.99) {
                //     path.style.strokeDasharray = "none";
                // } else {
                //     path.style.strokeDasharray = pathLength + ' ' + pathLength;
                // }
                let block = document.querySelector('.how-work__block__ul').clientHeight / 10.5;
                // console.log('block', block);
                if ((scrollTopBlockY * -1) >= 0 && (scrollTopBlockY * -1) <= block) {
                    index1 = 1;
                    let item = document.getElementById(`this${index1}`);
                    item.classList.add('how-work__block--active');
                    index2 = 2;
                    let item2 = document.getElementById(`this${index2}`);
                    if (item2.classList.length > 1) {
                        item2.classList.remove('how-work__block--active')
                    }
                    // this.setActives(0, 1)
                }
                if ((scrollTopBlockY * -1) > block && (scrollTopBlockY * -1) <= block * 2) {
                    index1 = 1;
                    let item1 = document.getElementById(`this${index1}`);
                    if (item1.classList.length > 1) {
                        item1.classList.remove('how-work__block--active');
                    }
                    index2 = 2;
                    let item2 = document.getElementById(`this${index2}`);
                    item2.classList.add('how-work__block--active');
                    index3 = 3;
                    let item3 = document.getElementById(`this${index3}`);
                    if (item3.classList.length > 1) {
                        item3.classList.remove('how-work__block--active');
                    }
                    // this.setActives(1, 2)
                }
                if ((scrollTopBlockY * -1) > block * 2 && (scrollTopBlockY * -1) <= block * 3) {
                    index1 = 2;
                    let item2 = document.getElementById(`this${index1}`);
                    if (item2.classList.length > 1) {
                        item2.classList.remove('how-work__block--active');
                    }
                    index2 = 3;
                    let item = document.getElementById(`this${index2}`);
                    item.classList.add('how-work__block--active');
                    index3 = 4;
                    let item3 = document.getElementById(`this${index3}`);
                    if (item3.classList.length > 1) {
                        item3.classList.remove('how-work__block--active');
                    }
                    // this.setActives(2, 3)
                }
                if ((scrollTopBlockY * -1) >= block * 3 && (scrollTopBlockY * -1) <= block * 4) {
                    index1 = 3;
                    let item2 = document.getElementById(`this${index1}`);
                    if (item2.classList.length > 1) {
                        item2.classList.remove('how-work__block--active');
                    }
                    index2 = 4;
                    let item = document.getElementById(`this${index2}`);
                    item.classList.add('how-work__block--active');
                    index3 = 5;
                    let item3 = document.getElementById(`this${index3}`);
                    if (item3.classList.length > 1) {
                        item3.classList.remove('how-work__block--active');
                    }
                    // this.setActives(2, 3)
                }
                if ((scrollTopBlockY * -1) >= block * 4 && (scrollTopBlockY * -1) <= block * 5) {
                    index1 = 4;
                    let item2 = document.getElementById(`this${index1}`);
                    if (item2.classList.length > 1) {
                        item2.classList.remove('how-work__block--active');
                    }
                    index2 = 5;
                    let item = document.getElementById(`this${index2}`);
                    item.classList.add('how-work__block--active');
                    // this.setActives(2, 3)
                }
            }
        }
    }
    setActives(index1, index2) {
        let item2 = document.getElementById(`this${index1}`);
        item2.classList.remove('how-work__block--active');

        let item = document.getElementById(`this${index2}`);
        item.classList.add('how-work__block--active')
    }

    componentDidMount() {
        if (window.location.pathname === '/') {
            window.addEventListener("scroll", this.scrollChange)
        }
    }

    componentWillUnmount() {
    }

    render() {

        let {
            steps,
        } = this.state;


        return (
            <React.Fragment>
                <div className="how-work" id="how-work">
                    {/*<div className="how-work__back">*/}
                        {/*<div className="how-work__back--steps">*/}
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="870.1" height="884.44" viewBox="0 0 1491 1617.01">*/}
                                {/*<defs>*/}
                                    {/*<linearGradient id="a" data-name="Безымянный градиент 3" x1="1014.5" y1="403.5" x2="1014.5" y2="2041.5" gradientUnits="userSpaceOnUse">*/}
                                        {/*<stop offset="0" stopColor="#FBDA61"/>*/}
                                        {/*<stop offset="1" stopColor="#FF5ACD"/>*/}
                                    {/*</linearGradient>*/}
                                {/*</defs>*/}
                                {/*<path className="how-work__back--steps--svg--patch" id="star-path" d="M654.5,2013.5h-165c-203,0-203,0-203-223,0-128,72-213,196-213,492.05,0,766.3,1,1041,1,219,0,219,0,219-177,0-106-56-179-206-179-460,0-218-1-710,0h-337c-203,0-203,0-203-223,0-128,72-213,196-213,492.05,0,766.3,1,1041,1,219,0,219,0,219-177,0-106-56-179-206-179-460,0-758,0-1250,1" transform="translate(-258.5 -403.5)"/>*/}
                            {/*</svg>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="how-work__back">*/}
                    {/*    <img className="how-work__back--img" src={spaceOne}/>*/}
                    {/*    <img className="how-work__back--img" src={spaceTwo}/>*/}
                    {/*    <img className="how-work__back--img" src={spaceThree}/>*/}
                    {/*</div>*/}
                    {/*<div className="how-work__back">*/}
                    {/*    <img className="how-work__back--img--left" src={imSix}/>*/}
                    {/*    <img className="how-work__back--img--right" src={imSeven}/>*/}
                    {/*</div>*/}
                    <div className="how-work__block">
                        <div className="how-work__block__head">
                            <h1>Этапы <b>разработки</b></h1>
                        </div>
                        <ul className="how-work__block__ul" id="steps">
                            {
                                steps.map((item, index) => {
                                    return (
                                        <li key={index} className="how-work__block__ul--li">
                                            <div
                                                className='how-work__block__ul--li--box'
                                                id={`this${index + 1}`}
                                            >
                                                <div className="how-work__block__ul--li--box--num">
                                                    <div className="how-work__block__ul--li--box--num--name">
                                                        <img src={item.img}/>
                                                    </div>
                                                </div>
                                                <div className="how-work__block__ul--li--box--info">
                                                    <div className="how-work__block__ul--li--box--info--title">
                                                        {
                                                            item.title
                                                        }
                                                    </div>
                                                    <div className="how-work__block__ul--li--box--info--desc">
                                                        {
                                                            item.desc
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
