import React from 'react'
import './styles/HelpEmail.scss'
import imOne from '../../assets/images/search.svg'
import imTwo from '../../assets/images/ssl.svg'
import imThree from '../../assets/images/servers.svg'
import imFour from '../../assets/images/database-storage.svg'
import imFive from '../../assets/images/mail.svg'
import imSix from '../../assets/images/support.svg'
import SendEmailNew from "../SendEmailNew";
import {Link} from "react-router-dom";
// import imOne from '../../assets/images/progress__monochromatic.svg'
// import imOne from '../../assets/images/analytics_process_monochromatic.svg'
// import imOneAnim from '../../assets/images/one-block--anim.svg'

export default class HelpEmail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <SendEmailNew
                    ref={(node) => (this.modalSend = node)}
                />
                <div className="promoe" id="promo">
                    <div className="promoe__container">
                        <h1 className="promoe__container--title">
                            <div className="promoe__container--title--one">
                                НУЖНА ПОМОЩЬ?
                            </div>
                            <div className="promoe__container--title--info">
                                Напишите нам — мы решим любой вопрос и подберем нужное решение для Вас.
                            </div>
                        </h1>
                        <div className="promoe__container--block">
                            <div className="head-new__container--box--four" onClick={() => {
                                this.modalSend.open()
                            }}>
                                Получить консультацию
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
