import React from 'react'

import './styles/Main.scss'

import imgOne from "../../assets/images/block-img-one.svg";
import imgTwo from "../../assets/images/block-img-two.svg";
import imgThree from "../../assets/images/block-img-three.svg";
import imgFour from "../../assets/images/block-img-four.svg";
import imgFive from "../../assets/images/block-img-five.svg";
import imgSix from "../../assets/images/block-img-six.svg";
import Benefits from "../../components/Benefits";
// import imTwo from "../../assets/images/comp_process.svg";
import imTwo from "../../assets/images/about-chat.svg";
import Preloader from "../../components/Preloader";

export default class PrivacyPolicy extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            infoComp: {
                title: 'Контакты',
                desc: 'Email: feedback@landlix.ru',
                img: imTwo,
                data: '/about-us'
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let { menu, menuActive } = this.state;
        return (
            <React.Fragment>
                <Preloader preloader={true}/>
                <div className="privacy-policy">
                    <p><strong>Политика конфиденциальности</strong><br />Настоящая Политика конфиденциальности регулирует порядок, в котором &laquo;Landlix&raquo; собирает, использует, хранит и раскрывает информацию, полученную от пользователей (каждый из которых &laquo;Пользователь&raquo;) веб-сайта landlix.ru (&laquo;Сайт&raquo;). Настоящая политика конфиденциальности распространяется на Сайт и все продукты и услуги, предлагаемые &laquo;Landlix&raquo;.</p>
                    <p><strong>Персональная идентификационная информация</strong><br />Мы можем собирать личную идентификационную информацию от Пользователей различными способами, включая когда пользователи посещают наш сайт, регистрируются на &laquo;сайте&raquo; и отправляет &laquo;форму заказа&raquo; или подписываются на рассылку, а также в связи с другими видами деятельности, услугами , функции или ресурсы, которые мы делаем доступными на нашем сайте. В зависимости от обстоятельств у пользователей могут запрашиваться имя, адрес электронной почты, почтовый адрес, номер телефона, информация о кредитной карте.</p>
                    <p><strong>Однако пользователи могут посещать наш сайт анонимно.</strong><br />Мы будем собирать личную идентификационную информацию от пользователей только в том случае, если они добровольно предоставят нам такую информацию. Пользователи всегда могут отказаться от предоставления личной идентификационной информации, за исключением того, что это может помешать им участвовать в определенных действиях, связанных с сайтом.</p>
                    <p><strong>Неличная идентификационная информация</strong><br />Мы можем собирать неличную идентификационную информацию о пользователях, когда они взаимодействуют с нашим сайтом. Неличная идентификационная информация может включать имя браузера, тип компьютера и техническую информацию о пользователях, средствах связи с нашим Сайтом, такие как операционная система и используемые поставщики интернет-услуг, а также другую подобную информацию.</p>
                    <p><strong>Файлы cookie веб-браузера</strong><br />Наш сайт может использовать &laquo;cookie&raquo; для улучшения взаимодействия с пользователем. Веб-браузер пользователя размещает файлы cookie на жестком диске для целей учета и иногда для отслеживания информации о них. Пользователь может настроить свой веб-браузер так, чтобы он отказывался от файлов cookie или предупреждал вас об отправке файлов cookie. Если они это сделают, обратите внимание, что некоторые части сайта могут работать неправильно.</p>
                    <p><strong>Как мы используем собранную информацию</strong><br />&laquo;Landlix&raquo; собирает и использует личную информацию Пользователей в следующих целях:</p>
                    <ul>
                        <li>Чтобы улучшить обслуживание клиентов<br />Ваша информация помогает нам более эффективно реагировать на ваши запросы обслуживания клиентов и потребности в поддержке.</li>
                        <li>Чтобы персонализировать пользовательский опыт<br />Мы можем использовать информацию в совокупности, чтобы понять, как наши Пользователи как группа используют услуги и ресурсы, предоставляемые на нашем Сайте.</li>
                        <li>Чтобы улучшить наш сайт<br />Мы постоянно стремимся улучшить предложения нашего веб-сайта на основе информации и обратной связи, которую мы получаем от вас.</li>
                        <li>Обрабатывать транзакции<br />Мы можем использовать информацию, которую пользователи предоставляют о себе при размещении заказа, только для оказания услуг по этому заказу. Мы не передаем эту информацию сторонним лицам, за исключением случаев, когда это необходимо для предоставления услуги.</li>
                    </ul>
                    <p><strong>Для администрирования конкурса, продвижения, опроса или других функций сайта</strong><br />Для отправки Пользователям информации, которую они согласились получить, по темам, которые, по нашему мнению, будут им интересны.</p>
                    <p><strong>Отправлять периодические электронные письма</strong><br />Адрес электронной почты, который пользователи предоставляют для обработки заказа, будет использоваться только для отправки им информации и обновлений, касающихся их заказа. Он также может быть использован для ответа на их запросы и / или другие запросы или вопросы. Если Пользователь решит подписаться на нашу рассылку, он получит электронные письма, которые могут содержать новости &laquo;Landlix&raquo;, обновления, информацию о продуктах или услугах и т. Д. Если в любое время Пользователь захочет отказаться от подписки на получение будущих электронных писем, мы включаем подробные инструкции по отмене подписки внизу каждого электронного письма, или пользователь может связаться с нами через наш сайт.</p>
                    <p><strong>Как мы защищаем вашу информацию</strong><br />Мы применяем соответствующие методы сбора, хранения и обработки данных и меры безопасности для защиты от несанкционированного доступа, изменения, раскрытия или уничтожения вашей личной информации, имени пользователя, пароля, информации о транзакциях и данных, хранящихся на нашем Сайте.</p>
                    <p>Обмен конфиденциальными и конфиденциальными данными между Сайтом и его Пользователями происходит по защищенному каналу связи SSL и зашифрован и защищен цифровыми подписями.</p>
                    <p><strong>Соблюдение защиты конфиденциальности детей в Интернете</strong><br />Защита неприкосновенности частной жизни очень молодых особенно важна. По этой причине мы никогда не собираем и не храним информацию на нашем Сайте от тех, кому на самом деле известно, что они моложе 18 лет, и ни одна из частей нашего веб-сайта не имеет структуры для привлечения лиц моложе 18 лет.</p>
                    <p><strong>Изменения в этой политике конфиденциальности</strong><br />&laquo;Landlix&raquo; имеет право по своему усмотрению обновлять эту политику конфиденциальности в любое время. Когда мы это сделаем, пересмотрите обновленную дату внизу этой страницы. Мы рекомендуем пользователям регулярно проверять эту страницу на наличие изменений, чтобы быть в курсе того, как мы помогаем защитить личную информацию, которую мы собираем. Вы признаете и соглашаетесь с тем, что вы обязаны периодически просматривать эту политику конфиденциальности и узнавать об изменениях.</p>
                    <p><strong>Ваше согласие с этими условиями</strong><br />Используя этот сайт, вы выражаете свое согласие с этой политикой и условиями обслуживания. Если вы не согласны с этой политикой, пожалуйста, не используйте наш сайт. Ваше дальнейшее использование Сайта после публикации изменений в этой политике будет считаться вашим согласием с этими изменениями.</p>
                </div>
            </React.Fragment>
        )
    }
}
